import React from 'react'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'
import { Validate } from 'components/atoms/Validate'

import { CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

interface AgreementCheckboxProps {
  value: boolean
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: boolean) => void
  isForceValidationEnabled: boolean
}

export const AgreementCheckbox: React.FC<AgreementCheckboxProps> = ({
  value,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  const handleChange = (checked: boolean): void => {
    setValue(checked)
  }

  return (
    <Validate rules={validation.rules} skipWaitBlur={isForceValidationEnabled}>
      {(isValid, brokenRule, handleValidateFocus, handleBlur) => (
        <React.Fragment>
          {!isValid && (
            <Paper bottom={8}>
              <Typography size={14} color="error">
                <Typo>{validation.errors[brokenRule]}</Typo>
              </Typography>
            </Paper>
          )}
          <CheckboxWithLabel
            label={
              <Typography size={14}>
                <Typo>
                  I confirm I have accurately represented my personal information, and have read and agree to the above
                  and{' '}
                  <Link to="/terms/" hard blank data-test-id="termsAndConditionsLink">
                    Terms and Conditions
                  </Link>{' '}
                  and{' '}
                  <Link to="/risk-disclosure/" hard blank data-test-id="riskDisclosureLink">
                    Risk Disclosure Statement
                  </Link>
                  .
                </Typo>
              </Typography>
            }
            size="smaller"
            checked={value}
            value="agreed"
            onChange={(value) => {
              handleChange(value)
              handleBlur()
            }}
            onFocus={handleValidateFocus}
            outline
            required
            data-test-id="confirmIsaTransferCheckbox"
          />
        </React.Fragment>
      )}
    </Validate>
  )
}
