import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import { Stack } from 'components/atoms/Stack'

import { RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { TransferCurrentYear } from 'constants/transferIsa'

interface CurrentYearTransferProps {
  value: TransferCurrentYear
  setValue: (value: TransferCurrentYear) => void
}

const CurrentYearTransfer: React.FC<CurrentYearTransferProps> = ({ value, setValue }) => {
  return (
    <RadioGroup
      selectedValue={value}
      onChange={(value: TransferCurrentYear) => {
        setValue(value)
      }}
    >
      <Stack vertical={16}>
        <RadioWithLabel
          CustomInput={Radio}
          label="No"
          value={TransferCurrentYear.NO}
          size="smaller"
          onChange={() => {}}
          name="transferCurrentYear"
          testId="transferCurrentYearNo"
          data-test-id="transferCurrentYearNo"
        />
        <RadioWithLabel
          CustomInput={Radio}
          label="Yes, transfer my current tax year ISA"
          value={TransferCurrentYear.YES}
          size="smaller"
          onChange={() => {}}
          name="transferCurrentYear"
          testId="transferCurrentYearYes"
          data-test-id="transferCurrentYearYes"
        />
      </Stack>
    </RadioGroup>
  )
}

export { CurrentYearTransfer }
