import { useUnit } from 'effector-react'

import { useCallback, useContext, useSelector, useActions } from 'hooks'

import { trackEvent } from 'helpers/analytics'
import moment from 'helpers/date.js'
import { features } from 'helpers/features'
import { getKeyOrDefault } from 'helpers/object'
import { goTo, urlTo } from 'helpers/router.js'

import { $bankAccountsStore } from 'app/effector/bank-accounts'
import { $quickStartsStore } from 'app/effector/quickStart'
import { $recurringPaymentsStore } from 'app/effector/recurringPayments'

import { updateOrCreate as updateOrCreateGoalAction } from 'app/redux/actions/portfolios'
import {
  isClientCompleted as selectIsClientCompleted,
  isClientApproved as selectIsClientApproved,
  isClientNewOrNotCompleted as selectIsClientNewOrNotCompleted,
  selectIsGoalInClosing as selectIsPortfolioInClosing,
} from 'app/redux/selectors'

import { setAnimation } from 'components/molecules/Modal'

import { ScrollSaverContext } from 'app/containers/ScrollSaver'

import { ChangeGoalTunnelContext as ChangePortfolioTunnelContext } from 'app/pages/Dashboard/Goals/ChangeGoalTunnel/ChangeGoalTunnel.jsx'
import { useTransferIsaForm } from 'app/pages/Dashboard/Goals/hooks'

import { useAutoinvestInOptions } from './useAutoinvestInOptions'
import { usePortfolioRebalance } from './usePortfolioRebalance.js'

import {
  states as portfolioStates,
  regulatoryTypes as portfolioRegulatoryTypes,
  manageTypes as portfolioManageTypes,
} from 'constants/portfolio'
import { quickStartStatuses } from 'constants/quickStart'

enum ActionKey {
  CASH_BALANCE_BREAKDOWN = 'CASH_BALANCE_BREAKDOWN',
  AUTOINVEST = 'AUTOINVEST',
  SELL_PORTFOLIO = 'SELL_PORTFOLIO',
  ADD_FUNDS = 'ADD_FUNDS',
  WITHDRAW = 'WITHDRAW',
  EDIT_WEIGHTS = 'EDIT_WEIGHTS',
  EDIT_PROJECTIONS = 'EDIT_PROJECTIONS',
  REBALANCE = 'REBALANCE',
  SAVINGS_PLAN = 'SAVINGS_PLAN',
  TRANSFER_ISA = 'TRANSFER_ISA',
  TRANSFER_SIPP = 'TRANSFER_SIPP',
  RENAME_PORTFOLIO = 'RENAME_PORTFOLIO',
  ANALYTICS = 'ANALYTICS',
  TRANSACTIONS = 'TRANSACTIONS',
  DELETE_PORTFOLIO = 'DELETE_PORTFOLIO',
  MOVE_CASH_IN = 'MOVE_CASH_IN',
  MOVE_CASH_OUT = 'MOVE_CASH_OUT',
}

enum ActionGroup {
  FUNDING = 'Funding & Withdrawals',
  PORTFOLIO = 'Portfolio',
}

enum ActionStatus {
  ON = 'On',
  OFF = 'Off',
}

type Action = {
  key: ActionKey
  title: string
  group: ActionGroup
  icon?: string
  status?: ActionStatus
  visible: boolean
  enabled: boolean
  onClick: (back?) => void
  'data-test-id'?: string
}

type UsePortfolioActionsInterface = {
  isRebalanceLoading: boolean
  isRebalanceRestricted: boolean
  rebalanceRestrictionType: string | null
  handleRebalanceRestrictionModalClose: () => void
  actionsMap: Record<string, Action>
  actions: Action[]
  isRecurringPaymentAvailableForSetup: boolean
}

const usePortfolioActions = (
  portfolio,
  handleAmendPortfolioSelect,
  showProjections = true,
): UsePortfolioActionsInterface => {
  const [updateOrCreateGoal] = useActions([updateOrCreateGoalAction])

  const portfolioId: number | undefined = portfolio?.id
  const portfolioManageType: typeof portfolioManageTypes.DIY | typeof portfolioManageTypes.MANAGED | undefined =
    portfolio?.manage_type

  // Remove `as`‘es when `ChangePortfolioTunnelContext` will be rewritten to ts
  const { tunnelQuery } = useContext(ChangePortfolioTunnelContext) as unknown as {
    tunnelQuery: Record<string, string>
  }

  const {
    isRebalanceLoading,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    handleRebalanceRestrictionModalClose,
    createRebalanceOrder,
    isTradingSuspended,
  } = usePortfolioRebalance(portfolioId)

  const autoinvestAction = useAutoinvestInOptions({ portfolio, tunnelQuery })

  const { resetAllScrollPositions } = useContext(ScrollSaverContext)

  const availableForMoveOutPortfoliosCount = useSelector((state: any) => {
    const portfolios = state.portfolios.list.getVisiblePortfolios()

    if (portfolio.regulatory_type === portfolioRegulatoryTypes.SIPP) {
      return portfolios.filter(
        ({ regulatory_type: regulatoryType }) => regulatoryType === portfolioRegulatoryTypes.SIPP,
      ).length
    }

    if (portfolio?.regulatory_type === portfolioRegulatoryTypes.ISA) {
      return portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === portfolioRegulatoryTypes.ISA)
        .length
    }

    return portfolios.length
  })
  const availableForMoveInPortfoliosCount = useSelector((state: any) => {
    const portfolios = state.portfolios.list.getVisiblePortfolios()

    if (portfolio.regulatory_type === portfolioRegulatoryTypes.SIPP) {
      return portfolios.filter(({ regulatory_type: regulatoryType }) =>
        [portfolioRegulatoryTypes.SIPP, portfolioRegulatoryTypes.GIA].includes(regulatoryType),
      ).length
    }

    const availableForMovePortfolios =
      portfolio?.regulatory_type === portfolioRegulatoryTypes.GIA
        ? portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType === portfolioRegulatoryTypes.GIA)
        : portfolios.filter(({ regulatory_type: regulatoryType }) => regulatoryType !== portfolioRegulatoryTypes.SIPP)

    return availableForMovePortfolios.length
  })
  const { nominatedAccount: nominatedBankAccount } = useUnit($bankAccountsStore)
  const isNominatedBankSupportsRecurringPayment = nominatedBankAccount?.bank?.recurring_payment_support
  const isClientNewOrNotCompleted = useSelector(selectIsClientNewOrNotCompleted)
  const isClientCompleted = useSelector(selectIsClientCompleted)
  const isClientApproved = useSelector(selectIsClientApproved)
  const isPortfolioInClosing = useSelector(selectIsPortfolioInClosing)
  const isAddFundsEnabled = !isPortfolioInClosing
  const isTransferIsaLinkVisible = portfolio?.regulatory_type === portfolioRegulatoryTypes.ISA
  const isTransferSippLinkVisible = portfolio?.regulatory_type === portfolioRegulatoryTypes.SIPP
  const isTransferIsaLinkEnabled = [portfolioStates.COMPLETED, portfolioStates.APPROVED].includes(portfolio?.state)
  const { hasReccuringPaymentByPortfolioId, hasDirectDebitByPortfolioId } = useUnit($recurringPaymentsStore)
  const hasRecurringPayment = hasReccuringPaymentByPortfolioId(portfolioId)
  const hasDirectDebitSubscription = hasDirectDebitByPortfolioId(portfolioId)
  const hasSubscription = hasRecurringPayment || hasDirectDebitSubscription
  const { getQuickStartByPortfolioId } = useUnit($quickStartsStore)
  const quickStart = getQuickStartByPortfolioId(portfolio.id)
  const isRebalanceEnabled = parseFloat(portfolio?.current_balance) > 0
  const isRecurringPaymentAvailableForSetup =
    (features.get('recurring-payments-release') && isNominatedBankSupportsRecurringPayment) || isClientNewOrNotCompleted
  const isManagedPortfolio = portfolio?.manage_type === portfolioManageTypes.MANAGED
  const gotFirstTopup = portfolio?.first_topup || parseFloat(portfolio.current_balance) >= 100
  const isTopupBeforeSavingsPlanNeeded = isClientApproved && isManagedPortfolio && !gotFirstTopup

  const getQueryWithBack = useCallback(
    (back?: string, additional?: Record<string, string | number | boolean>) => {
      let query: Record<string, string | number | boolean> = { ...tunnelQuery, ...additional }

      if (back) {
        query = { ...query, back }
      }

      return query
    },
    [tunnelQuery],
  )

  const handleOpenAddFunds = useCallback(
    (back?: string) => {
      trackEvent({
        category: 'Add funds',
        action: 'Click ‘Add funds’',
        portfolioRegulatoryType: portfolio.regulatory_type,
        portfolioPresetType: portfolio.preset_type,
      })

      if (isClientNewOrNotCompleted) {
        const isQuickStartInProgress = quickStart?.status === quickStartStatuses.ACTIVE

        goTo(
          urlTo(
            `portfolio.add-funds.finish-registration`,
            { id: portfolioId },
            isQuickStartInProgress ? { ...tunnelQuery, quickStart: true } : tunnelQuery,
          ),
          {
            scrollToTop: false,
          },
        )
        return
      }

      if (isClientCompleted) {
        goTo(urlTo(`portfolio.add-funds.not-approved`, { id: portfolioId }, tunnelQuery), {
          scrollToTop: false,
        })
        return
      }

      // That's a hack
      setAnimation('true')
      setTimeout(() => {
        setAnimation('false')
      }, 50)

      goTo(urlTo(`portfolio.add-funds`, { id: portfolioId }, getQueryWithBack(back)), {
        scrollToTop: false,
      })
    },
    [
      portfolio.regulatory_type,
      portfolio.preset_type,
      isClientNewOrNotCompleted,
      isClientCompleted,
      portfolioId,
      getQueryWithBack,
      tunnelQuery,
      quickStart,
    ],
  )

  const handleOpenSell = useCallback(() => {
    if (portfolio?.weights_setup_needed) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { holdingsChanged: null }))
      return
    }

    goTo(urlTo(`portfolio.sell`, { id: portfolioId }))
  }, [portfolio?.weights_setup_needed, portfolioId])

  const handleOpenSavingsPlan = useCallback(
    (back?: string, sendAnalyticsEvent: boolean = true) => {
      if (hasSubscription) {
        goTo(
          urlTo(
            `portfolio.options.${hasRecurringPayment ? 'recurring-payment-edit' : 'direct-debit-form'}`,
            { id: portfolioId },
            getQueryWithBack(back),
          ),
        )
        return
      }

      if (sendAnalyticsEvent) {
        trackEvent({
          action: 'sp_setup_in_menu_options_clicked',
          manage_type: portfolio?.manage_type,
          ...(portfolio?.preset_type && { preset: portfolio.preset_type }),
          portfolio_created_date: moment(portfolio?.created).format('YYYY-MM-DD'),
        })
      }

      if (portfolio?.weights_setup_needed) {
        goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { holdingsChanged: null }))
        return
      }

      if (isClientCompleted) {
        goTo(urlTo('portfolio.add-funds.not-approved', { id: portfolioId }, tunnelQuery), { scrollToTop: false })
        return
      }

      const isQuickStartInProgress = quickStart?.status === quickStartStatuses.ACTIVE && isClientNewOrNotCompleted

      if (isQuickStartInProgress) {
        goTo(
          urlTo('portfolio.add-funds.finish-registration', { id: portfolioId }, { ...tunnelQuery, quickStart: true }),
          {
            scrollToTop: false,
          },
        )
        return
      }

      if (isTopupBeforeSavingsPlanNeeded) {
        goTo(
          urlTo('portfolio.options.recurring-payment.fund-your-portfolio', { id: portfolioId }, getQueryWithBack(back)),
        )
        return
      }

      const nextUrl = urlTo(
        `portfolio.options.${isRecurringPaymentAvailableForSetup ? 'recurring-payment' : 'direct-debit'}`,
        { id: portfolioId },
        getQueryWithBack(back),
      )

      if (portfolio?.regulatory_type === portfolioRegulatoryTypes.SIPP) {
        goTo(
          urlTo(
            'portfolio.add-funds.net-contributions',
            { id: portfolioId },
            { ...getQueryWithBack(back), next: nextUrl },
          ),
        )
        return
      }

      goTo(nextUrl)
    },
    [
      hasSubscription,
      hasRecurringPayment,
      portfolio,
      portfolioId,
      getQueryWithBack,
      isClientCompleted,
      isClientNewOrNotCompleted,
      tunnelQuery,
      isTopupBeforeSavingsPlanNeeded,
      quickStart,
      isRecurringPaymentAvailableForSetup,
    ],
  )

  const handleOpenWithdraw = useCallback(() => {
    trackEvent({
      category: 'Portfolio panel',
      action: 'Withdraw funds clicked',
    })

    if (portfolio?.regulatory_type === portfolioRegulatoryTypes.SIPP) {
      goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { ...tunnelQuery, withdrawSippModalOpened: true }))
      return
    }

    goTo(urlTo(`portfolio.withdraw-funds`, { id: portfolioId }, tunnelQuery))
  }, [portfolioId, tunnelQuery, portfolio?.regulatory_type])

  const handleOpenCashBreakdown = useCallback(
    (back?: string) => {
      goTo(urlTo(`dashboard.portfolio.options.cash-breakdown`, { id: portfolioId }, getQueryWithBack(back)))
    },
    [portfolioId, getQueryWithBack],
  )

  const handleEditWeights = useCallback(
    (back?: string) => {
      resetAllScrollPositions()

      goTo(urlTo(`portfolio.edit-weights`, { id: portfolioId }, getQueryWithBack(back)))
    },
    [portfolioId, resetAllScrollPositions, getQueryWithBack],
  )

  const handleRebalance = useCallback(() => {
    if (portfolio?.weights_setup_needed) {
      goTo(urlTo(`dashboard.portfolio`, { id: portfolioId }, { holdingsChanged: null }))
      return
    }

    createRebalanceOrder(portfolioId, tunnelQuery, isTradingSuspended)
  }, [portfolio?.weights_setup_needed, createRebalanceOrder, portfolioId, tunnelQuery, isTradingSuspended])

  const { onlineFormProps } = useTransferIsaForm({
    portfolioId: portfolioId ? `${portfolioId}` : undefined,
  })
  const handleTransferIsa = useCallback(
    (back?: string) => {
      if (isClientNewOrNotCompleted) {
        goTo(
          urlTo(
            `portfolio.add-funds.finish-registration`,
            { id: portfolioId },
            { ...tunnelQuery, action: 'transferIsa' },
          ),
          {
            scrollToTop: false,
          },
        )
        return
      }

      updateOrCreateGoal(['is_isa_transfer'], { is_isa_transfer: true }, true, portfolioId) as unknown as {
        portfolios: { error: Error }
      }

      goTo(onlineFormProps.to, { replace: back?.includes('options') })
    },
    [isClientNewOrNotCompleted, portfolioId, tunnelQuery, onlineFormProps, updateOrCreateGoal],
  )
  const handleTransferSipp = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', { id: portfolioId }, { ...tunnelQuery, transferSipp: true }))
  }, [tunnelQuery, portfolioId])

  const handleChangePortfolioTitle = useCallback(
    (back?: string) => {
      goTo(urlTo(`portfolio.change-portfolio-name`, { id: portfolioId }, getQueryWithBack(back)), {
        replace: true,
        scrollToTop: false,
      })
    },
    [portfolioId, getQueryWithBack],
  )

  const handleAnalytics = useCallback(() => {
    resetAllScrollPositions()

    goTo(urlTo(`analytics.portfolio`, { portfolioId }, getQueryWithBack(undefined, { fromPortfolio: true })))
  }, [portfolioId, resetAllScrollPositions, getQueryWithBack])

  const handleTransactions = useCallback(() => {
    resetAllScrollPositions()

    goTo(urlTo(`transactions`, { id: portfolioId }, getQueryWithBack(undefined, { portfolio_id: `${portfolioId}` })))
  }, [portfolioId, resetAllScrollPositions, getQueryWithBack])

  const handleMoveCashIn = useCallback(
    (back?: string) => {
      goTo(urlTo(`portfolio.options.move-cash-in`, { id: portfolioId }, getQueryWithBack(back)))
    },
    [portfolioId, getQueryWithBack],
  )

  const handleMoveCashOut = useCallback(
    (back?: string) => {
      goTo(urlTo(`portfolio.options.move-cash-out`, { id: portfolioId }, getQueryWithBack(back)))
    },
    [portfolioId, getQueryWithBack],
  )

  const handleDelete = useCallback(
    (back?: string) => {
      goTo(urlTo(`portfolio.options.delete-portfolio`, { id: portfolioId }, getQueryWithBack(back)))
    },
    [portfolioId, getQueryWithBack],
  )

  const actionTitles = {
    CASH_BALANCE_BREAKDOWN: 'Cash balance breakdown',
    AUTOINVEST: 'AutoInvest available cash',
    SELL_PORTFOLIO: 'Sell portfolio',
    ADD_FUNDS: 'Add cash',
    WITHDRAW: 'Withdraw cash',
    EDIT_WEIGHTS: 'Edit portfolio',
    EDIT_PROJECTIONS: 'View projections / Amend allocation',
    REBALANCE: 'Rebalance portfolio',
    SAVINGS_PLAN: hasSubscription ? 'Savings Plan' : 'Set up a Savings Plan',
    TRANSFER_ISA: 'Transfer an ISA',
    TRANSFER_SIPP: 'Transfer a Personal Pension',
    RENAME_PORTFOLIO: 'Rename portfolio',
    ANALYTICS: 'Analytics',
    TRANSACTIONS: 'Transactions',
    MOVE_CASH_IN: 'Move cash in',
    MOVE_CASH_OUT: 'Move cash out',
    DELETE_PORTFOLIO: 'Delete portfolio',
  }

  const actionsMap = {
    [ActionKey.CASH_BALANCE_BREAKDOWN]: {
      key: ActionKey.CASH_BALANCE_BREAKDOWN,
      title: actionTitles.CASH_BALANCE_BREAKDOWN,
      group: ActionGroup.FUNDING,
      visible: true,
      enabled: true,
      onClick: handleOpenCashBreakdown,
      'data-test-id': 'portfolioCashBreakdown',
    },
    [ActionKey.AUTOINVEST]: autoinvestAction,
    [ActionKey.SAVINGS_PLAN]: {
      key: ActionKey.SAVINGS_PLAN,
      title: actionTitles.SAVINGS_PLAN,
      group: ActionGroup.FUNDING,
      status: hasSubscription ? ActionStatus.ON : ActionStatus.OFF,
      visible: true,
      enabled: !hasSubscription ? isAddFundsEnabled : true,
      onClick: handleOpenSavingsPlan,
      'data-test-id': 'portfolioSavingsPlan',
    },
    [ActionKey.ADD_FUNDS]: {
      key: ActionKey.ADD_FUNDS,
      title: actionTitles.ADD_FUNDS,
      group: ActionGroup.FUNDING,
      visible: true,
      enabled: isAddFundsEnabled,
      onClick: handleOpenAddFunds,
      'data-test-id': 'portfolioAddFunds',
    },
    [ActionKey.WITHDRAW]: {
      key: ActionKey.WITHDRAW,
      title: actionTitles.WITHDRAW,
      group: ActionGroup.FUNDING,
      visible: true,
      enabled: isClientApproved,
      onClick: handleOpenWithdraw,
      'data-test-id': 'portfolioWithdraw',
    },
    [ActionKey.MOVE_CASH_OUT]: {
      key: ActionKey.MOVE_CASH_OUT,
      title: actionTitles.MOVE_CASH_OUT,
      group: ActionGroup.FUNDING,
      visible: true,
      enabled: isClientApproved && availableForMoveOutPortfoliosCount > 1,
      onClick: handleMoveCashOut,
      'data-test-id': 'portfolioMoveCashOut',
    },
    [ActionKey.MOVE_CASH_IN]: {
      key: ActionKey.MOVE_CASH_IN,
      title: actionTitles.MOVE_CASH_IN,
      group: ActionGroup.FUNDING,
      visible: true,
      enabled: isClientApproved && availableForMoveInPortfoliosCount > 1,
      onClick: handleMoveCashIn,
      'data-test-id': 'portfolioMoveCashIn',
    },
    [ActionKey.ANALYTICS]: {
      key: ActionKey.ANALYTICS,
      title: actionTitles.ANALYTICS,
      group: ActionGroup.PORTFOLIO,
      icon: 'piechart-24',
      visible: true,
      enabled: true,
      onClick: handleAnalytics,
      'data-test-id': 'portfolioAnalytics',
    },
    [ActionKey.TRANSACTIONS]: {
      key: ActionKey.TRANSACTIONS,
      title: actionTitles.TRANSACTIONS,
      group: ActionGroup.PORTFOLIO,
      visible: true,
      enabled: true,
      onClick: handleTransactions,
      'data-test-id': 'portfolioTransactions',
    },
    [ActionKey.EDIT_WEIGHTS]: {
      key: ActionKey.EDIT_WEIGHTS,
      title: actionTitles.EDIT_WEIGHTS,
      group: ActionGroup.PORTFOLIO,
      visible: true,
      enabled: true,
      onClick: handleEditWeights,
      'data-test-id': 'editPortfolioWeights',
    },
    [ActionKey.EDIT_PROJECTIONS]: {
      key: ActionKey.EDIT_PROJECTIONS,
      title: actionTitles.EDIT_PROJECTIONS,
      group: ActionGroup.PORTFOLIO,
      visible: showProjections,
      enabled: tunnelQuery?.view !== 'Projections',
      onClick: handleAmendPortfolioSelect,
      'data-test-id': 'editPortfolioProjections',
    },
    [ActionKey.REBALANCE]: {
      key: ActionKey.REBALANCE,
      title: actionTitles.REBALANCE,
      group: ActionGroup.PORTFOLIO,
      visible: true,
      enabled: isRebalanceEnabled,
      onClick: handleRebalance,
      'data-test-id': 'rebalancePortfolioHoldings',
    },
    [ActionKey.SELL_PORTFOLIO]: {
      key: ActionKey.SELL_PORTFOLIO,
      title: actionTitles.SELL_PORTFOLIO,
      group: ActionGroup.PORTFOLIO,
      visible: true,
      enabled: parseFloat(portfolio?.current_balance) > 0,
      onClick: handleOpenSell,
      'data-test-id': 'portfolioSell',
    },
    [ActionKey.TRANSFER_ISA]: {
      key: ActionKey.TRANSFER_ISA,
      title: actionTitles.TRANSFER_ISA,
      group: ActionGroup.PORTFOLIO,
      visible: isTransferIsaLinkVisible,
      enabled: isTransferIsaLinkEnabled,
      onClick: handleTransferIsa,
      'data-test-id': 'portfolioTransferIsa',
    },
    [ActionKey.TRANSFER_SIPP]: {
      key: ActionKey.TRANSFER_SIPP,
      title: actionTitles.TRANSFER_SIPP,
      group: ActionGroup.PORTFOLIO,
      visible: isTransferSippLinkVisible,
      enabled: true,
      onClick: handleTransferSipp,
      'data-test-id': 'portfolioTransferSipp',
    },
    [ActionKey.RENAME_PORTFOLIO]: {
      key: ActionKey.RENAME_PORTFOLIO,
      title: actionTitles.RENAME_PORTFOLIO,
      group: ActionGroup.PORTFOLIO,
      visible: true,
      enabled: true,
      onClick: handleChangePortfolioTitle,
      'data-test-id': 'portfolioRename',
    },
    [ActionKey.DELETE_PORTFOLIO]: {
      key: ActionKey.DELETE_PORTFOLIO,
      title: actionTitles.DELETE_PORTFOLIO,
      group: ActionGroup.PORTFOLIO,
      visible: portfolio?.is_deletable ?? false,
      enabled: true,
      onClick: handleDelete,
      'data-test-id': 'portfolioDelete',
    },
  }

  const actions = getKeyOrDefault(
    {
      default: [
        actionsMap[ActionKey.SAVINGS_PLAN],
        actionsMap[ActionKey.ADD_FUNDS],
        actionsMap[ActionKey.WITHDRAW],
        actionsMap[ActionKey.MOVE_CASH_IN],
        actionsMap[ActionKey.MOVE_CASH_OUT],
        actionsMap[ActionKey.ANALYTICS],
        actionsMap[ActionKey.TRANSFER_ISA],
        actionsMap[ActionKey.TRANSFER_SIPP],
        actionsMap[ActionKey.RENAME_PORTFOLIO],
        actionsMap[ActionKey.DELETE_PORTFOLIO],
      ],
      [portfolioManageTypes.CASH]: [
        actionsMap[ActionKey.ADD_FUNDS],
        actionsMap[ActionKey.SAVINGS_PLAN],
        actionsMap[ActionKey.TRANSFER_ISA],
        actionsMap[ActionKey.TRANSFER_SIPP],
        actionsMap[ActionKey.WITHDRAW],
        actionsMap[ActionKey.MOVE_CASH_IN],
        actionsMap[ActionKey.MOVE_CASH_OUT],
        actionsMap[ActionKey.TRANSACTIONS],
      ],
      [portfolioManageTypes.MANAGED]: [
        actionsMap[ActionKey.SAVINGS_PLAN],
        actionsMap[ActionKey.WITHDRAW],
        actionsMap[ActionKey.MOVE_CASH_IN],
        actionsMap[ActionKey.MOVE_CASH_OUT],
        actionsMap[ActionKey.ANALYTICS],
        actionsMap[ActionKey.TRANSACTIONS],
        actionsMap[ActionKey.EDIT_PROJECTIONS],
        actionsMap[ActionKey.TRANSFER_ISA],
        actionsMap[ActionKey.TRANSFER_SIPP],
        actionsMap[ActionKey.RENAME_PORTFOLIO],
        actionsMap[ActionKey.DELETE_PORTFOLIO],
      ],
      [portfolioManageTypes.SELF_SELECTED]: [
        actionsMap[ActionKey.SAVINGS_PLAN],
        actionsMap[ActionKey.WITHDRAW],
        actionsMap[ActionKey.MOVE_CASH_IN],
        actionsMap[ActionKey.MOVE_CASH_OUT],
        actionsMap[ActionKey.ANALYTICS],
        actionsMap[ActionKey.TRANSACTIONS],
        actionsMap[ActionKey.TRANSFER_ISA],
        actionsMap[ActionKey.TRANSFER_SIPP],
        actionsMap[ActionKey.RENAME_PORTFOLIO],
        actionsMap[ActionKey.DELETE_PORTFOLIO],
      ],
      [portfolioManageTypes.DIY]: [
        actionsMap[ActionKey.CASH_BALANCE_BREAKDOWN],
        actionsMap[ActionKey.AUTOINVEST],
        actionsMap[ActionKey.SAVINGS_PLAN],
        actionsMap[ActionKey.ADD_FUNDS],
        actionsMap[ActionKey.WITHDRAW],
        actionsMap[ActionKey.MOVE_CASH_IN],
        actionsMap[ActionKey.MOVE_CASH_OUT],
        actionsMap[ActionKey.ANALYTICS],
        actionsMap[ActionKey.TRANSACTIONS],
        actionsMap[ActionKey.EDIT_WEIGHTS],
        actionsMap[ActionKey.REBALANCE],
        actionsMap[ActionKey.SELL_PORTFOLIO],
        actionsMap[ActionKey.TRANSFER_ISA],
        actionsMap[ActionKey.TRANSFER_SIPP],
        actionsMap[ActionKey.RENAME_PORTFOLIO],
        actionsMap[ActionKey.DELETE_PORTFOLIO],
      ],
    },
    portfolioManageType,
  )
    .filter((action) => Boolean(action))
    .filter((action) => action.visible)

  return {
    isRebalanceLoading,
    isRebalanceRestricted,
    rebalanceRestrictionType,
    handleRebalanceRestrictionModalClose,
    actionsMap,
    actions,
    isRecurringPaymentAvailableForSetup,
  }
}

export { usePortfolioActions, ActionKey, ActionGroup, ActionStatus, type Action }
