import { type Nullable, nullable, string, int, decimal, enumValue } from 'app/effector/model'

import { TransferCurrentYear, TransferPreviousYear } from 'constants/transferIsa'

type IsaTransferPrefillFormDataFields = {
  isa_provider_id: Nullable<number> | 'other'
  isa_provider_name: Nullable<string>
  isa_account: Nullable<string>
  transfer_current_year: TransferCurrentYear
  current_year_amount: Nullable<string>
  transfer_previous_years: TransferPreviousYear
  previous_years_amount: Nullable<string>
}

type ClientIsaTransferPrefillFormDataFields = IsaTransferPrefillFormDataFields & {
  isa_transfer: number | null
}

type FormField = keyof IsaTransferPrefillFormDataFields
type FormValue = IsaTransferPrefillFormDataFields[FormField]

class IsaTransferPrefillFormData {
  isa_provider_id: Nullable<number> | 'other'
  isa_provider_name: Nullable<string>
  isa_account: Nullable<string>
  transfer_current_year: TransferCurrentYear
  current_year_amount: Nullable<string>
  transfer_previous_years: TransferPreviousYear
  previous_years_amount: Nullable<string>

  constructor(data: Partial<IsaTransferPrefillFormDataFields>) {
    this.isa_provider_id = data.isa_provider_id === 'other' ? 'other' : nullable(int)(data.isa_provider_id)
    this.isa_provider_name = nullable(string)(data.isa_provider_name)
    this.isa_account = nullable(string)(data.isa_account)
    this.transfer_current_year =
      nullable(enumValue)(data.transfer_current_year, TransferCurrentYear) ?? TransferCurrentYear.NO
    this.current_year_amount = nullable(decimal)(data.current_year_amount)
    this.transfer_previous_years =
      nullable(enumValue)(data.transfer_previous_years, TransferPreviousYear) ?? TransferPreviousYear.NO
    this.previous_years_amount = nullable(decimal)(data.previous_years_amount)
  }

  set(key: FormField, value: FormValue): IsaTransferPrefillFormData {
    return new IsaTransferPrefillFormData({ ...this, [key]: value })
  }

  serialize(): IsaTransferPrefillFormDataFields {
    return {
      isa_provider_id: this.isa_provider_id === 'other' ? null : this.isa_provider_id,
      isa_provider_name: this.isa_provider_id === 'other' ? this.isa_provider_name : null,
      isa_account: this.isa_account as string,
      transfer_current_year: this.transfer_current_year,
      current_year_amount: this.transfer_current_year === TransferCurrentYear.NO ? null : this.current_year_amount,
      transfer_previous_years: this.transfer_previous_years,
      previous_years_amount:
        this.transfer_previous_years === TransferPreviousYear.NO ? null : this.previous_years_amount,
    }
  }
}

export {
  IsaTransferPrefillFormData,
  type IsaTransferPrefillFormDataFields,
  type FormField,
  type FormValue,
  type ClientIsaTransferPrefillFormDataFields,
}
