import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

interface CurrentYearAmountProps {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const CurrentYearAmount: React.FC<CurrentYearAmountProps> = ({
  value,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <Validate rules={validation.rules} errors={validation.errors} skipWaitBlur={isForceValidationEnabled}>
      <LabelInlineStyle
        labelText="Total current tax year ISA contributions"
        multiline
        size="small"
        errorMessages={validation.errors}
      >
        <Input
          type="money"
          onChange={(_, value) => {
            setValue(value)
          }}
          withFloat
          data-test-id="transferCurrentYearAmount"
        >
          {value ?? ''}
        </Input>
      </LabelInlineStyle>
    </Validate>
  )
}

export { CurrentYearAmount }
