import { createStore, createEvent, sample } from 'effector'

import { bulkValidate } from 'helpers/validation.js'

import { $isaTransferProviderList } from 'app/effector/isa-transfer'

import { type IsaTransferPrefillFormDataFields } from 'app/pages/CreateAccount/components/IsaTransferPrefillForm/IsaTransferPrefillFormData'

import { IsaTransferFormData, type FormField, type FormValue } from './IsaTransferFormData'

import { TransferCurrentYear, TransferPreviousYear } from 'constants/transferIsa'

// Events
export const setField = createEvent<{ field: FormField; value: FormValue }>()
export const enableForceValidation = createEvent()
export const setFormFromPrefilledData = createEvent<IsaTransferPrefillFormDataFields>()
export const setPortfolioId = createEvent<number>()
export const resetForm = createEvent()

// Stores
export const $form = createStore<IsaTransferFormData>(new IsaTransferFormData({}))
export const $forceValidationEnabled = createStore<boolean>(false)

export const $isaProviderOptions = $isaTransferProviderList.map((providerList) =>
  providerList.map((provider) => ({
    value: provider.id ?? 'other',
    name: provider.name,
  })),
)

// Store updates
$form.on(setField, (state, { field, value }) => state.set(field, value)).reset(resetForm)
$forceValidationEnabled.on(enableForceValidation, () => true).reset(resetForm)

// Set from prefilled data
sample({
  clock: setFormFromPrefilledData,
  source: $form,
  fn: (formData, prefilledData) => {
    const newFormData = { ...formData, ...prefilledData }

    if (newFormData.isa_provider_id === null) {
      newFormData.isa_provider_id = 'other'
    }
    return new IsaTransferFormData(newFormData)
  },
  target: $form,
})

// set portfolio_id
sample({
  clock: setPortfolioId,
  fn: (portfolioId) => ({ field: 'portfolio_id' as FormField, value: portfolioId }),
  target: setField,
})

export const $validation = $form.map((form) => {
  const isTransferForCurrentYearSelected = form.transfer_current_year === TransferCurrentYear.YES
  const isTransferForPreviousYearsSelected = form.transfer_previous_years !== TransferPreviousYear.NO
  const currentYearAmount = parseFloat(form.current_year_amount ?? '0')
  const previousYearsAmount = parseFloat(form.previous_years_amount ?? '0')

  return {
    portfolio_id: {
      rules: [form.portfolio_id !== null],
      errors: ['Please select destination of ISA transfer'],
    },
    isa_provider_id: {
      rules: [form.isa_provider_id !== null],
      errors: ['Please select a provider or enter a custom one'],
    },
    isa_provider_name: {
      rules: [!!form.isa_provider_name || form.isa_provider_id !== 'other'],
      errors: ['Isa provider name cannot be empty'],
    },
    isa_account: {
      rules: [!!form.isa_account],
      errors: ['ISA account cannot be empty'],
    },
    current_year_amount: {
      rules: [
        !isTransferForCurrentYearSelected || currentYearAmount > 0,
        !isTransferForCurrentYearSelected || currentYearAmount <= 20000,
      ],
      errors: ['Total current tax cannot be empty', 'Total current tax cannot exceed £20,000'],
    },
    previous_years_amount: {
      rules: [!isTransferForPreviousYearsSelected || previousYearsAmount > 0],
      errors: ['Value of previous years cannot be empty'],
    },
    isa_transfer_details: {
      rules: [isTransferForCurrentYearSelected || isTransferForPreviousYearsSelected],
      errors: ['Please choose transfer type'],
    },
    agreed: {
      rules: [form.agreed],
      errors: ['This field is required'],
    },
  }
})

export const $isValid = $validation.map((validation) => !!bulkValidate(validation))
