import React from 'react'

import { useSelector } from 'hooks'

import { urlTo } from 'helpers/router'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

const TransferSippLink = (): React.ReactElement => {
  const shouldSignSippDeclaration = useSelector((state) => !state.client.agreed_with_sipp_declaration)

  const transferSippLink = urlTo(
    'dashboard',
    null,
    shouldSignSippDeclaration ? { startTransferSipp: true } : { transferSipp: true },
  )

  return (
    <Link to={transferSippLink} style={{ display: 'block' }} data-test-id="transferSippButton">
      <ItemWithIcon
        inline
        space={12}
        icon={<Icon type="arrow-in-an-outlined-circle-right-32" size={32} color="inherit" />}
        content={
          <Typography size={14} lineHeight="small" color="inherit">
            <Typo>Transfer a Vanguard pension</Typo>
          </Typography>
        }
        contentVerticalAlign="center"
      />
      <Paper left={40}>
        <Paper left={4}>
          <Typography size={14} lineHeight="small" color="minor">
            <Typo>Transfer a pension from your Vanguard account.</Typo>
          </Typography>
        </Paper>
      </Paper>
    </Link>
  )
}

export { TransferSippLink }
