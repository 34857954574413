import type React from 'react'

import classNames from 'classnames/dedupe'
import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'

import { useRef, useMediaQueries, useMemo, useState, useLayoutEffect, useDebouncedCallback, useCallback } from 'hooks'

import {
  LIMIT_TICKS_COUNT,
  LIMIT_TICKS_COUNT_LESS,
  DESKTOP_PADDING,
  PHONE_PADDING,
  HORDE_DATA_BOUNDARY,
} from '../constants'
import { getTicks } from '../utils'

import type { IncomeLineDataItem } from '../IncomeLine'

type UseLayoutProps = {
  data: IncomeLineDataItem[]
  className?: string
  hoverable: boolean
  showLessTicks: boolean
}

type UseLayoutInterface = {
  classes: string
  svgRef: React.RefObject<SVGElement>
  width: number
  height: number
  chartPadding: number
  showTicks: Array<number | null>
}

function useLayout({ data, className, hoverable, showLessTicks }: UseLayoutProps): UseLayoutInterface {
  const mediaQueries = useMediaQueries()
  const { desktop } = mediaQueries

  const svgRef = useRef<SVGElement>(null)

  const dataPrimitive = useMemo(() => data?.map((item) => item?.value).join(''), [data])
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  const getNewDimensions = useCallback(
    (callback?: () => void) => {
      setHeight(svgRef.current?.getBoundingClientRect?.()?.height ?? 0)
      setWidth(svgRef.current?.getBoundingClientRect?.()?.width ?? 0)

      callback?.()
    },
    [svgRef],
  )

  const getPeakCoordinates = useCallback(() => {
    const svgNode = svgRef.current
    const minNode = svgRef.current?.querySelector('[data-income-line-min="true"]')
    const maxNode = svgRef.current?.querySelector('[data-income-line-max="true"]')
    const currentNode = svgRef.current?.querySelector('[data-income-line-current="true"]')

    if (svgNode && minNode && maxNode && currentNode) {
      const svgBoundingClientRect = svgNode.getBoundingClientRect()
      const minBoundingClientRect = minNode.getBoundingClientRect()
      const maxBoundingClientRect = maxNode.getBoundingClientRect()
      const currentBoundingClientRect = currentNode.getBoundingClientRect()

      return {
        min: {
          right: svgBoundingClientRect.right - minBoundingClientRect.right + minBoundingClientRect.width / 2,
          bottom: svgBoundingClientRect.bottom - minBoundingClientRect.bottom + minBoundingClientRect.height / 2,
        },
        max: {
          right: svgBoundingClientRect.right - maxBoundingClientRect.right + maxBoundingClientRect.width / 2,
          bottom: svgBoundingClientRect.bottom - maxBoundingClientRect.bottom + maxBoundingClientRect.height / 2,
        },
        current: {
          right: svgBoundingClientRect.right - currentBoundingClientRect.right + currentBoundingClientRect.width / 2,
          bottom:
            svgBoundingClientRect.bottom - currentBoundingClientRect.bottom + currentBoundingClientRect.height / 2,
        },
      }
    }
  }, [svgRef])

  const sendPeakEvent = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent('IncomeLine:Rendered', {
        detail: getPeakCoordinates(),
      }),
    )
  }, [getPeakCoordinates])

  const setDimensions = useCallback(() => {
    getNewDimensions(sendPeakEvent)
  }, [getNewDimensions, sendPeakEvent])
  const setDimensionsDebounced = useDebouncedCallback(setDimensions, 250, [setDimensions])

  useLayoutEffect(() => {
    setDimensions()

    window.addEventListener('resize', setDimensionsDebounced)

    return () => {
      window.removeEventListener('resize', setDimensionsDebounced)
    }
  }, [dataPrimitive, setDimensions, setDimensionsDebounced])

  const chartPadding = useMemo(() => (desktop ? DESKTOP_PADDING : PHONE_PADDING), [desktop])

  const showTicks = useMemo(
    () => (desktop ? getTicks(data, showLessTicks ? LIMIT_TICKS_COUNT_LESS : LIMIT_TICKS_COUNT) : [0, data.length - 1]),
    [data, showLessTicks, desktop],
  )

  const classes = classNames(className, 'IncomeLine', getMediaQuieryClasses('IncomeLine', mediaQueries), {
    IncomeLine_hoverable: hoverable,
    IncomeLine_horde: data.length >= HORDE_DATA_BOUNDARY,
    IncomeLine_exactlyTwoDataPoints: data.length === 2,
  })

  return {
    classes,
    svgRef,
    width,
    height,
    chartPadding,
    showTicks,
  }
}

export { useLayout, type UseLayoutInterface }
