import React from 'react'

import { useRef } from 'hooks'

type FormProps = {
  children: React.ReactNode
  onSubmit: (...args: any) => any
} & React.HTMLProps<HTMLFormElement>

const Form = ({ children, onSubmit, ...rest }: FormProps): JSX.Element => {
  const ref = useRef<HTMLFormElement | null>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const form = ref.current

    if (!form) return

    const isFormValid = form.checkValidity()

    if (!isFormValid) {
      for (let i = 0; i < form.length; i++) {
        const input = form[i] as HTMLInputElement
        input.focus()
        input.blur()
      }

      const invalidInput = form.querySelector('input:invalid') as HTMLInputElement

      invalidInput?.focus()
      return
    }

    onSubmit()
  }

  return (
    <form ref={ref} onSubmit={handleSubmit} noValidate {...rest}>
      {children}
    </form>
  )
}

export default Form
