import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'

interface IsaProviderProps {
  value: string | number | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  options: Array<{ value: string | number; name: string | null }>
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const IsaProvider: React.FC<IsaProviderProps> = ({
  value,
  validation,
  options,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <Validate rules={validation.rules} skipWaitBlur={isForceValidationEnabled}>
      <LabelInlineStyle labelText="Existing ISA provider" multiline size="small" errorMessages={validation.errors}>
        <Input
          type="select"
          placeholder="Choose your ISA provider"
          options={options}
          onChange={(_, value: string): void => {
            setValue(value)
          }}
          forceUnnative
        >
          {value}
        </Input>
      </LabelInlineStyle>
    </Validate>
  )
}

export { IsaProvider }
