import React from 'react'
import { PropTypes } from 'prop-types'

import {
  combineErrors,
  backendErrorsToObj,
  bulkValidate,
  additionalFieldsToValidation,
  birthdayRules,
  nameRules,
  phoneRules,
} from 'helpers/validation.js'
import { prepareName } from 'helpers/forms.js'
import { useCallback, useSelector } from 'hooks'
import { useUnit } from 'effector-react'

import Form, { Fieldset } from 'components/_old/Form/Form.jsx'
import Validate from 'components/_old/Validate/Validate.jsx'
import Label, { LabelText } from 'components/_old/Label/Label.jsx'
import { RadioGroup, Radio } from 'react-radio-group'
import Input from 'components/_old/Input/Input.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Link from 'components/_old/Link/Link.jsx'
import SubmitOnEnter from 'components/_old/SubmitOnEnter/SubmitOnEnter.jsx'
import { RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import CleanInputOnFocus from 'components/atoms/CleanInputOnFocus/CleanInputOnFocus.jsx'

import { $owner, $error, resetError } from 'app/effector/contacts'
import { $dictsStore } from 'app/effector/dicts'

const isPartiallyHidden = (str) => /\*/.test(str)

const PersonalForm = ({ submitButton, wrapButton, inputMods, additionalFields, onOwnerFieldChange, onSubmit }) => {
  const client = useSelector((state) => state.client)
  const owner = useUnit($owner) ?? {}
  const error = useUnit($error)

  const { dicts } = useUnit($dictsStore)
  const { genders } = dicts

  const { country_code_by_ip } = client

  const handleSubmit = useCallback(() => {
    onOwnerFieldChange('first_name', prepareName(owner.first_name))
    if (owner.middle_name) {
      onOwnerFieldChange('middle_name', prepareName(owner.middle_name))
    }
    onOwnerFieldChange('last_name', prepareName(owner.last_name))

    let fields = ['gender', 'first_name', 'middle_name', 'last_name']

    if (!isPartiallyHidden(owner.phone)) {
      fields = [...fields, 'phone']
    }

    if (!isPartiallyHidden(owner.birthday)) {
      fields = [...fields, 'birthday']
    }

    if (onSubmit) {
      onSubmit(fields)
    }
  }, [onOwnerFieldChange, onSubmit, owner])

  const handleOwnerFieldChange = useCallback(
    (fieldName, value) => {
      resetError()
      onOwnerFieldChange(fieldName, value)
    },
    [onOwnerFieldChange],
  )

  if (!owner.id) {
    return null
  }

  const validation = combineErrors(
    {
      gender: {
        rules: [owner.gender !== null],
        errors: ['Gender should be specified'],
      },
      first_name: nameRules(owner.first_name, 'First name'),
      middle_name: nameRules(owner.middle_name, 'Middle name', false),
      last_name: nameRules(owner.last_name, 'Last name'),
      birthday: birthdayRules(owner.birthday),
      phone: phoneRules(owner.phone),
    },
    additionalFieldsToValidation(additionalFields),
    backendErrorsToObj(error),
  )

  const preFields = (() => {
    if (!additionalFields?.pre) {
      return null
    } else {
      return Object.keys(additionalFields.pre).map((f) => {
        return additionalFields.pre[f].getField(validation)
      })
    }
  })()

  submitButton = React.cloneElement(
    submitButton,
    {
      onClick: handleSubmit,
      disabled: !bulkValidate(validation),
      tabIndex: 5,
      'data-test-id': 'personalFormSubmit',
    },
    submitButton.props.children || 'Continue',
  )

  submitButton = wrapButton(submitButton)

  return (
    <SubmitOnEnter>
      <Form>
        {preFields}
        <Fieldset marginNext>
          <Validate rules={validation.gender.rules}>
            <Label errorMessages={validation.gender.errors}>
              <LabelText>
                <Text>Gender</Text>
              </LabelText>
              <RadioGroup selectedValue={owner.gender} onChange={(value) => handleOwnerFieldChange('gender', value)}>
                <ColumnarLayout fluid>
                  {genders?.map((gender) => (
                    <Column size={0} key={gender.name}>
                      <RadioWithLabel
                        CustomInput={Radio}
                        label={gender.name}
                        value={gender.id}
                        data-test-id="personalFormGenderRadio"
                      />
                    </Column>
                  ))}
                </ColumnarLayout>
              </RadioGroup>
            </Label>
          </Validate>
          <Validate rules={validation.first_name.rules}>
            <Label errorMessages={validation.first_name.errors} data-test-id="personalFormFirstNameLabel">
              First name
              <Input
                name="fname"
                autoComplete="given-name"
                mods={inputMods}
                type="text"
                onChange={(_event, value) => handleOwnerFieldChange('first_name', value)}
                tabIndex={1}
                data-test-id="personalFormFirstNameInput"
              >
                {owner.first_name}
              </Input>
            </Label>
          </Validate>
          <Validate rules={validation.middle_name.rules}>
            <Label
              errorMessages={validation.middle_name.errors}
              postfield={<Text muted>Optional</Text>}
              data-test-id="personalFormMiddleNameLabel"
            >
              Middle name
              <Input
                name="mname"
                autoComplete="additional-name"
                mods={inputMods}
                type="text"
                onChange={(_event, value) => handleOwnerFieldChange('middle_name', value)}
                tabIndex={1}
                data-test-id="personalFormMiddleNameInput"
              >
                {owner.middle_name}
              </Input>
            </Label>
          </Validate>
          <Validate rules={validation.last_name.rules}>
            <Label errorMessages={validation.last_name.errors} data-test-id="personalFormLastNameLabel">
              Last name
              <Input
                name="lname"
                autoComplete="family-name"
                mods={inputMods}
                type="text"
                onChange={(_event, value) => handleOwnerFieldChange('last_name', value)}
                tabIndex={2}
                data-test-id="personalFormLastNameInput"
              >
                {owner.last_name}
              </Input>
            </Label>
          </Validate>
          <Validate rules={validation.birthday.rules}>
            <Label errorMessages={validation.birthday.errors} data-test-id="personalFormBirthdayLabel">
              Date of birth
              <CleanInputOnFocus
                onSetValue={(value) => handleOwnerFieldChange('birthday', value || '')}
                cleanIfMatch={/\*/}
              >
                <Input
                  name="birthday"
                  mods={inputMods}
                  type="date"
                  onChange={(_event, value) => handleOwnerFieldChange('birthday', value)}
                  tabIndex={3}
                  data-test-id="personalFormBirthdayInput"
                >
                  {owner.birthday}
                </Input>
              </CleanInputOnFocus>
            </Label>
          </Validate>
          <Validate rules={validation.phone.rules}>
            <Label errorMessages={validation.phone.errors} data-test-id="personalFormPhoneLabel">
              Phone
              <CleanInputOnFocus onSetValue={(value) => handleOwnerFieldChange('phone', value)} cleanIfMatch={/\*/}>
                <Input
                  name="phone"
                  type="tel"
                  mods={inputMods}
                  country={country_code_by_ip}
                  onChange={(_event, value) => handleOwnerFieldChange('phone', value)}
                  tabIndex={4}
                  disabled={owner.phone_verified}
                  data-test-id="personalFormPhoneInput"
                >
                  {owner.phone}
                </Input>
              </CleanInputOnFocus>
            </Label>
          </Validate>
        </Fieldset>
        {preFields && (
          <Fieldset>
            <Text small>
              If&nbsp;you would like to&nbsp;change your information please contact&nbsp;us through&nbsp;the{' '}
              <Link to="https://help.investengine.com/" hard>
                Help Centre
              </Link>
            </Text>
          </Fieldset>
        )}
        {submitButton}
      </Form>
    </SubmitOnEnter>
  )
}

PersonalForm.propTypes = {
  inputMods: PropTypes.object,
  additionalFields: PropTypes.object,
  submitButton: PropTypes.node,
  wrapButton: PropTypes.func,
  onOwnerFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default PersonalForm
