import React, { Fragment } from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries } from 'hooks'

import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

import {
  IsaProvider,
  IsaProviderCustomName,
  AccountNumber,
  DetailsOfIsaTransfer,
  CurrentYearTransfer,
  CurrentYearAmount,
  PreviousYearsTransfer,
  PreviousYearsAmount,
  PortfolioSelect,
  DateField,
  AgreementCheckbox,
  AdditionalInfo,
} from 'components/organisms/IsaTransferForm'

import { $form, $validation, setField, $forceValidationEnabled, $isaProviderOptions } from '../../model/formModel'

import { TransferCurrentYear, TransferPreviousYear } from 'constants/transferIsa'

export const TransferIsaForm: React.FC = () => {
  const { desktop } = useMediaQueries()

  const [form, validation, handleSetField, forceValidationEnabled, isaProviderOptions] = useUnit([
    $form,
    $validation,
    setField,
    $forceValidationEnabled,
    $isaProviderOptions,
  ])

  const isTransferCurrentYear = form.transfer_current_year === TransferCurrentYear.YES
  const isTransferPreviousYears = form.transfer_previous_years !== TransferPreviousYear.NO
  const isOtherProviderSelected = form.isa_provider_id === 'other'

  const mainForm = (
    <Fragment>
      <Paper top={4} bottom={32}>
        <Typography size={24} lineHeight="small" weight="semibold">
          <Typo>Destination of ISA Transfer</Typo>
        </Typography>
      </Paper>

      <PortfolioSelect
        value={form.portfolio_id}
        validation={validation.portfolio_id}
        setValue={(value) => handleSetField({ field: 'portfolio_id', value })}
        isForceValidationEnabled={forceValidationEnabled}
      />

      <Paper top={40} bottom={32}>
        <Typography size={24} lineHeight="small" weight="semibold">
          <Typo>Existing ISA Provider</Typo>
        </Typography>
      </Paper>

      <IsaProvider
        value={form.isa_provider_id}
        validation={validation.isa_provider_id}
        setValue={(value) => handleSetField({ field: 'isa_provider_id', value })}
        isForceValidationEnabled={forceValidationEnabled}
        options={isaProviderOptions as any}
      />

      {isOtherProviderSelected && (
        <Paper top={24}>
          <IsaProviderCustomName
            value={form.isa_provider_name}
            validation={validation.isa_provider_name}
            setValue={(value) => handleSetField({ field: 'isa_provider_name', value })}
            isForceValidationEnabled={forceValidationEnabled}
          />
        </Paper>
      )}

      <Paper top={desktop ? 24 : 20}>
        <AccountNumber
          value={form.isa_account}
          validation={validation.isa_account}
          setValue={(value) => handleSetField({ field: 'isa_account', value })}
          isForceValidationEnabled={forceValidationEnabled}
        />
      </Paper>

      <Paper top={desktop ? 40 : 48}>
        <DetailsOfIsaTransfer
          validation={validation.isa_transfer_details}
          isForceValidationEnabled={forceValidationEnabled}
        />

        <Paper top={32}>
          <Typography size={16} lineHeight="small" weight="semibold">
            <Typo>Transfer current tax year ISA</Typo>
          </Typography>
        </Paper>

        <Paper top={16}>
          <CurrentYearTransfer
            value={form.transfer_current_year}
            setValue={(value) => handleSetField({ field: 'transfer_current_year', value })}
          />
        </Paper>

        {isTransferCurrentYear && (
          <Paper top={16}>
            <CurrentYearAmount
              value={form.current_year_amount}
              validation={validation.current_year_amount}
              setValue={(value) => handleSetField({ field: 'current_year_amount', value })}
              isForceValidationEnabled={forceValidationEnabled}
            />
          </Paper>
        )}

        <Paper top={32}>
          <Typography size={16} lineHeight="small" weight="semibold">
            <Typo>Previous years ISAs</Typo>
          </Typography>
        </Paper>

        <Paper top={16}>
          <PreviousYearsTransfer
            value={form.transfer_previous_years}
            setValue={(value) => handleSetField({ field: 'transfer_previous_years', value })}
          />
        </Paper>

        {isTransferPreviousYears && (
          <Paper top={16}>
            <PreviousYearsAmount
              value={form.previous_years_amount}
              validation={validation.previous_years_amount}
              setValue={(value) => handleSetField({ field: 'previous_years_amount', value })}
              isForceValidationEnabled={forceValidationEnabled}
            />
          </Paper>
        )}
      </Paper>
    </Fragment>
  )

  return (
    <Fragment>
      <Width size={24}>{mainForm}</Width>
      <Width size={45}>
        <Paper top={32}>
          <AdditionalInfo />
        </Paper>

        <Paper top={32}>
          <Typography size={24} lineHeight="small" weight="semibold">
            <Typo>Declaration</Typo>
          </Typography>
        </Paper>

        <Paper top={16}>
          <DateField />
        </Paper>

        <Paper top={24}>
          <AgreementCheckbox
            value={form.agreed}
            validation={validation.agreed}
            setValue={(value) => handleSetField({ field: 'agreed', value })}
            isForceValidationEnabled={forceValidationEnabled}
          />
        </Paper>
      </Width>
    </Fragment>
  )
}
