import localstore from 'helpers/localstore.js'

import { getFeatures } from 'app/redux/api/feature.js'
import { ApiError } from 'app/redux/models/errors'

class Features {
  static instance = null
  #features = localstore.get('features') || {}
  areFetched = false

  static getInstance() {
    if (!Features.instance) {
      Features.instance = new Features()
    }

    return Features.instance
  }

  emit = () => {
    window.dispatchEvent(new CustomEvent('featuresUpdated'))
  }

  get = (featureName) => this.#features?.[featureName] || false

  getHash = () => {
    if (!window) {
      return new Error('Can not get hash of `features` outside of browser environment')
    }

    return window.btoa(JSON.stringify(this.#features))
  }

  set = (featureName, value, shouldEmit = true) => {
    if (typeof value !== 'boolean') {
      value = value === 'true'
    }

    const newFeatures = { ...this.#features, [featureName]: value }

    this.#features = newFeatures
    localstore.set('features', newFeatures)

    if (shouldEmit) {
      this.emit()
    }

    return newFeatures
  }

  setBulk = (bulk, shouldEmit = true) => {
    Object.entries(bulk).forEach(([featureName, value]) => this.set(featureName, value, false))

    if (shouldEmit) {
      this.emit()
    }
  }

  clear = (shouldEmit = true) => {
    this.#features = {}
    localstore.set('features', {})

    if (shouldEmit) {
      this.emit()
    }
  }

  fetch = async () => {
    const features = await getFeatures()

    if (!(features instanceof ApiError)) {
      this.areFetched = true
      this.clear(false)
      this.setBulk(features)
    }

    return features
  }
}

const features = Features.getInstance()

export { features }
