import axios from 'helpers/ajax'

import { ApiError } from 'app/redux/models/errors'

import { type SignatureType } from '../models'

type IsaProviderDTO = {
  id: number
  name: string
  isa_regexp: string | null
  isa_error_message: string | null
  gia_regexp: string | null
  gia_error_message: string | null
  signature_type: SignatureType | null
}

const getFetchIsaProvidersURL = (): string => 'portfolios/isa-providers/'
const fetchIsaProviders = async (): Promise<IsaProviderDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchIsaProvidersURL())

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchIsaProviders.getUrl = getFetchIsaProvidersURL

const getCreateIsaTransferRequestURL = (): string => 'portfolios/transfer-isa/'
const createIsaTransferRequest = async (
  transferRequestDTO: IsaTransferRequestDTO,
): Promise<IsaTransferRequestDTO | ApiError> => {
  try {
    const { data } = await axios.post(getCreateIsaTransferRequestURL(), transferRequestDTO)

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
createIsaTransferRequest.getUrl = getCreateIsaTransferRequestURL

type TransferProgressStepDTO = {
  state: string
  error?: string
  short_name: string
  name: string
  description: string
}

type TransferProgressDTO = {
  id: number
  provider_name: string
  account_number: string
  steps: TransferProgressStepDTO[]
  estimated_completion_date: string
}

type FetchTransferProgressData = { portfolioId: number }

const getFetchTransferProgressesURL = ({ portfolioId }: FetchTransferProgressData): string =>
  `portfolios/${portfolioId}/transfers-from-nominess/`
const fetchTransferProgresses = async ({
  portfolioId,
}: FetchTransferProgressData): Promise<TransferProgressDTO[] | ApiError> => {
  try {
    const { data } = await axios.get(getFetchTransferProgressesURL({ portfolioId }))

    return data
  } catch (error) {
    return new ApiError(error)
  }
}
fetchTransferProgresses.getUrl = getFetchTransferProgressesURL

const dismissTransferProgressPanel = async (id: number): Promise<{ success: string } | ApiError> => {
  try {
    const { data } = await axios.post('notifications/close-panel/transfers-from-nominess/', { isa_transfer_id: id })

    return data
  } catch (error) {
    return new ApiError(error)
  }
}

type IsaTransferRequestDTO = {
  portfolio_id: number | null
  isa_provider_id: number | null
  isa_provider_name: string | null
  isa_account: string
  transfer_current_year: string
  current_year_amount: string | null
  transfer_previous_years: string
  previous_years_amount: string | null
}

type IsaTransferRequestResponseDTO = IsaTransferRequestDTO & {
  jotform_link: string
}

const submitIsaTransferRequest = async (
  transferRequestDTO: IsaTransferRequestDTO,
): Promise<IsaTransferRequestResponseDTO> => {
  const { data } = await axios.post('portfolios/transfer-isa/', transferRequestDTO)

  return data
}

export {
  type IsaProviderDTO,
  fetchIsaProviders,
  type IsaTransferRequestDTO,
  submitIsaTransferRequest,
  createIsaTransferRequest,
  type TransferProgressDTO,
  type TransferProgressStepDTO,
  fetchTransferProgresses,
  dismissTransferProgressPanel,
}
