import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { useSelector, useMediaQueries } from 'hooks'

import { currentYear } from 'helpers/date.js'
import { palette } from 'helpers/palette/'

import { getMediaQuieryClasses } from 'decorators/withMediaQueries/withMediaQueries.jsx'
import Inner from 'components/_old/Inner/Inner.jsx'
import Link from 'components/_old/Link/Link.jsx'
import Text from 'components/_old/Text/Text.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Width from 'components/_old/Width/Width'
import Card from 'components/_old/Card/Card.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import { Nobr } from 'components/_old/Nobr'
import ColumnarLayout, { Column } from 'components/molecules/ColumnarLayout/ColumnarLayout.jsx'
import StoresButtons from 'components/_old/StoresButtons/StoresButtons.jsx'
import { Typography } from 'components/atoms/Typography/Typography.jsx'
import { Paper } from 'components/atoms/Paper'
import { GatewayDest } from 'components/atoms/Gateway'

import './Footer.css'

const Footer = ({ noMarginTop }) => {
  const mediaQueries = useMediaQueries()
  const clientState = useSelector((state) => state.client.state)
  const isAuthorized = clientState !== 'NEW'

  const classes = classNames(
    'Footer',
    { Footer_noMarginTop: noMarginTop },
    getMediaQuieryClasses('Footer', mediaQueries),
  )

  return (
    <Fragment>
      <div className={classes}>
        <div className="Footer-Section">
          <Card className="Footer-Info" mods={{ theme: 'straight-corners', 'no-padding': 'right left' }}>
            <Inner className="Layout-Holder">
              <ColumnarLayout className="Footer-Contacts" mods={{ 'align-columns': 'center' }}>
                <Column size={1}>
                  <Text big style={{ color: palette['content-on-background-minor'] }}>
                    <Typo>
                      Our support is available <Nobr>Monday-Friday 5:30am to 11:00pm</Nobr>{' '}
                      <br className="HideOnPhones" />
                      <Nobr>and Saturday-Sunday 7:00am to 10:00pm</Nobr>
                    </Typo>
                  </Text>
                </Column>
                <Column size={0}>
                  <Width className="Footer-Button Width_mobileFullWidth" size={18}>
                    <Button
                      mods={{
                        size: 'big block',
                      }}
                    >
                      <Link classnameless to="https://help.investengine.com/" hard blank>
                        <Typo>Need help? Visit our Help Centre</Typo>
                      </Link>
                    </Button>
                  </Width>
                </Column>
              </ColumnarLayout>
            </Inner>
          </Card>
        </div>
        <Inner className="Layout-Holder">
          <Card
            mods={{
              theme: 'transparent',
              'no-padding': 'left right',
              padding: 'big',
            }}
          >
            <Text className="Text_leftOnPhones" center block>
              <Paper top={16}>
                <Typography size={18} align="center">
                  Whenever you invest, your capital is at risk
                </Typography>
              </Paper>
              <Paper top={16}>
                <Typo>This could mean the value of your investments goes down as well as up.</Typo>
                <br className="HideOnPhones" />
                <Link to="/risk-disclosure/" hard blank>
                  Understand more about investment risk
                </Link>
                .
              </Paper>
            </Text>
          </Card>
          <Card mods={{ theme: 'transparent', 'no-padding': 'right left' }}>
            <StoresButtons />
          </Card>
          <div className="Footer-HR" />
          <Card
            mods={{
              theme: 'transparent',
              'no-padding': 'left right',
              padding: 'big',
            }}
            fluid
          >
            {isAuthorized ? (
              <Typography size={12} color="minor">
                InvestEngine (UK) Limited is Authorised and Regulated by the Financial Conduct Authority, [FRN 801128].
                InvestEngine does not provide investment advice. If you are unsure of the risk or suitability of an
                investment, you should seek advice from an independent financial adviser.
              </Typography>
            ) : (
              <Typography size={12} color="minor">
                InvestEngine® is the trading name of InvestEngine Limited. InvestEngine (UK) Limited is Authorised and
                Regulated by the Financial Conduct Authority, [FRN 801128]. InvestEngine is incorporated in the UK with
                company number 10438231 and is registered at Lawford House, Albert Place, London, United Kingdom, N3
                1RL.
                <Paper top={12}>
                  InvestEngine does not provide investment advice. If you are unsure of the risk or suitability of an
                  investment, you should seek advice from an independent financial adviser.
                </Paper>
              </Typography>
            )}
          </Card>

          <Card mods={{ theme: 'transparent', 'no-padding': 'left right' }}>
            <Text block center>
              {`© InvestEngine Limited ${currentYear}. All rights reserved.`}
            </Text>
          </Card>
        </Inner>
      </div>
      <GatewayDest name="supportModal" />
    </Fragment>
  )
}

Footer.propTypes = {
  origin: PropTypes.string,
  noMarginTop: PropTypes.bool,
}

export default Footer
