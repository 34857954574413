import React from 'react'

import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import { Typo } from 'components/_old/Typo'
import Validate from 'components/_old/Validate/Validate.jsx'

import { Paper } from 'components/atoms/Paper'
import { Typography } from 'components/atoms/Typography'

interface PreviousYearsAmountProps {
  value: string | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: string) => void
  isForceValidationEnabled: boolean
}

const PreviousYearsAmount: React.FC<PreviousYearsAmountProps> = ({
  value,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  return (
    <React.Fragment>
      <Validate rules={validation.rules} errors={validation.errors} skipWaitBlur={isForceValidationEnabled}>
        <LabelInlineStyle
          labelText="Value of previous years ISAs to be transferred"
          multiline
          size="small"
          errorMessages={validation.errors}
        >
          <Input
            type="money"
            onChange={(_, value) => {
              setValue(value)
            }}
            withFloat
            data-test-id="transferPreviousYearAmount"
          >
            {value ?? ''}
          </Input>
        </LabelInlineStyle>
      </Validate>
      <Paper top={8}>
        <Typography size={14} lineHeight="small" color="minor">
          <Typo>Provide current value. Future value may change with markets.</Typo>
        </Typography>
      </Paper>
    </React.Fragment>
  )
}

export { PreviousYearsAmount }
