import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'
import { withRouter } from 'react-router'

import moment from 'moment-timezone'

import { useCallback, useEffect, useLoading, useMediaQueries, useSelector, useState } from 'hooks'

import { analyticsCIO } from 'helpers/analytics'
import { format as formatDate } from 'helpers/date'
import { goTo, urlTo } from 'helpers/router.js'
import { bulkValidate } from 'helpers/validation.js'

import { showSuccessToast, showFailToast } from 'app/redux/actions/ui'
import { createSippTransfer } from 'app/redux/api/portfolio'
import { ApiError } from 'app/redux/models/errors'

import AllCenter from 'components/_old/AllCenter/AllCenter.jsx'
import Button from 'components/_old/Button/Button.jsx'
import Input from 'components/_old/Input/Input.jsx'
import LabelInlineStyle from 'components/_old/LabelInlineStyle/LabelInlineStyle.jsx'
import { Typo } from 'components/_old/Typo/Typo'
import Width from 'components/_old/Width/Width'

import Form from 'components/atoms/Form/Form'
import { MobileLayout, MobileLayoutFooterButton } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'
import { Validate } from 'components/atoms/Validate'

import { Modal } from 'components/molecules/Modal'
import { Preloader } from 'components/molecules/Preloader'
import { RadioWithLabel, CheckboxWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { OwnerDetails } from 'app/pages/Dashboard/TransferIsa/TransferIsa/components/OwnerDetails'

import vanguardImage from './vanguard.png'

type SippTransferwModalProps = {
  params: {
    id: string
  }
  location: {
    query: {
      transferSipp: string
    }
  }
}

const ASSET_TYPES = {
  CASH: 'ENCASH',
  IN_SPECIE: 'IN_SPECIE',
}

const SippTransferModal = withRouter(({ params, location }: SippTransferwModalProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const { isLoading, wait } = useLoading(false)

  const [planNumber, setPlanNumber] = useState('')
  const [transferType, setTransferType] = useState('FULL')
  const [amount, setAmount] = useState(null)
  const [assetsType, setAssetsType] = useState('CASH')
  const [agreed, setAgreed] = useState(false)

  const { transferSipp, ...restQuery } = location?.query ?? {}
  const isOpened = transferSipp === 'true'
  const client = useSelector((state) => state.client)
  useEffect(() => {
    if (!isOpened) {
      setAgreed(false)
    }
  }, [isOpened])

  const validation = {
    plan_number: {
      rules: [planNumber.length > 0],
      errors: ['Plan number cannot be empty'],
    },
    amount: {
      rules: [parseFloat(amount ?? '0') > 0],
      errors: ['Amount cannot be empty'],
    },
  }

  const handlePlanNumberChange = (_, value): void => {
    setPlanNumber(value)
  }
  const handleAmountChange = (_, value): void => {
    setAmount(value)
  }
  const handleClose = useCallback(() => {
    goTo(urlTo('dashboard.portfolio', params, restQuery))
  }, [params, restQuery])

  const handleSubmit = async (): void => {
    if (!bulkValidate(validation) || !agreed) return

    analyticsCIO.identify({
      id: client.email,
      waiting_for_sipp_transfers: true,
      sipp_transfer_plan_number: planNumber,
      sipp_transfer_amount: amount,
      sipp_transfer_type: transferType,
      sipp_transfer_assets_type: assetsType,
      sipp_transfer_agreed_with_terms: Date.now(),
    })
    const sippTransferData = {
      portfolio: params.id,
      plan_number: planNumber,
      part_type: transferType,
      asset_transfer_type: ASSET_TYPES[assetsType],
      amount,
    }

    const data = await wait(createSippTransfer(sippTransferData))

    if (data instanceof ApiError) {
      showFailToast()
      return
    }
    handleClose()
    showSuccessToast('Your transfer request has been submitted successfully')
  }

  const headerNode = <NavigationBar rightPartText="Close" onRightPartClick={handleClose} />

  const buttonNode = (
    <Button mods={{ size: 'big block' }} type="submit" form="sipp-transfer-form">
      Submit
    </Button>
  )

  const content = (
    <Form onSubmit={handleSubmit} id="sipp-transfer-form" style={{ width: '100%' }}>
      <AllCenter>
        <img src={vanguardImage} width={54} height={54} />
      </AllCenter>
      <Paper top={16}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small" align="center">
          <Typo>Personal pension transfers: currently available for Vanguard</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography align="center">
          <Typo>
            We're currently finalising the transfer process for other pension providers to make sure it's as
            frictionless as possible – we'll let you know when it's ready.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={48}>
        <Typography size={desktop ? 32 : 24} weight="semibold" lineHeight="small">
          <Typo>SIPP transfer form</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>We can only proceed with this transfer if:</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography size={14}>
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            <li>Vanguard is your current pension provider;</li>
            <li>your pension is not in drawdown; and</li>
            <li>
              your pension is not subject to any existing or proposed divorce, earmarking or pension sharing order,
              trustee in bankruptcy order or any other type of court order.
            </li>
            <li>We’ll let you know when we can accept transfers from other pension providers.</li>
          </ul>
        </Typography>
      </Paper>
      <Paper top={48}>
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>Disclaimer:</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography>
          You are aware that Quai Investment Services Limited is being asked to transfer benefits from your existing
          scheme to the InvestEngine Personal Pension.
          <br />
          <br />
          Please read the disclaimer below carefully.
          <br />
          <br />
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            <li>
              I have neither sought nor been given any pension transfer advice or information from Quai Investment
              Services Limited or any of its employees on the subject of pension transfers.
            </li>
            <li>
              I have compared the benefits of the InvestEngine Personal Pension with those of the pension that I am
              transferring.
            </li>
            <li>
              I can confirm that I am aware that there may be some benefits available within my existing scheme that
              will not be available via the InvestEngine Personal Pension.
            </li>
            <li>
              I understand that if I have taken out protection then paying a contribution or making a transfer to the
              InvestEngine Personal Pension could negate this protection.
            </li>
            <li>
              I understand that the InvestEngine Personal Pension does not comply with the “stakeholder” criteria which
              impose certain conditions such as limiting charges to a maximum of 1.5% per annum for the first ten years
              and 1.0% per annum thereafter.
            </li>
          </ul>
        </Typography>
      </Paper>
      <Paper top={32}>
        <OwnerDetails />
      </Paper>
      <Paper top={48}>
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>Transfer information</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Validate rules={validation.plan_number.rules}>
          {(isValid) => (
            <LabelInlineStyle
              labelText="Vanguard pension plan reference"
              errorMessages={validation.plan_number.errors}
              size="small"
              multiline
            >
              <Paper top={4}>
                <Input type="text" onChange={handlePlanNumberChange} valid={isValid} required>
                  {planNumber}
                </Input>
              </Paper>
            </LabelInlineStyle>
          )}
        </Validate>
      </Paper>
      <Paper top={32}>
        <Paper bottom={16}>
          <Typography weight="semibold">Is this a full or partial transfer?</Typography>
        </Paper>
        <RadioGroup
          selectedValue={transferType}
          onChange={(value) => {
            setTransferType(value)
          }}
        >
          <Stack vertical={4}>
            <RadioWithLabel CustomInput={Radio} label="Full" value="FULL" size="smaller" />
            <RadioWithLabel CustomInput={Radio} label="Partial" value="PARTIAL" size="smaller" />
          </Stack>
        </RadioGroup>
      </Paper>
      <Paper top={32}>
        <Typography weight="semibold">
          If partial please confirm the amount to transfer or, if full, please provide an estimated transfer value:
        </Typography>
      </Paper>
      <Paper top={16}>
        <Validate rules={validation.amount.rules}>
          {(isValid) => (
            <LabelInlineStyle
              labelText="Amount to be transferred"
              errorMessages={validation.amount.errors}
              size="small"
              multiline
            >
              <Paper top={4}>
                <Input type="money" onChange={handleAmountChange} valid={isValid} required>
                  {amount}
                </Input>
              </Paper>
            </LabelInlineStyle>
          )}
        </Validate>
      </Paper>
      <Paper top={32}>
        <Paper bottom={16}>
          <Typography weight="semibold">Is this a cash transfer or an in-specie transfer of assets?</Typography>
        </Paper>
        <RadioGroup
          selectedValue={assetsType}
          onChange={(value) => {
            setAssetsType(value)
          }}
        >
          <Stack vertical={4}>
            <RadioWithLabel CustomInput={Radio} label="Cash" value="CASH" size="smaller" />
            <RadioWithLabel CustomInput={Radio} label="In-specie" value="IN_SPECIE" size="smaller" />
          </Stack>
        </RadioGroup>
      </Paper>
      <Paper top={16}>
        <Typography size={14}>
          <Typo>
            Please note that the InvestEngine Pension Plan can only accept Vanguard ETFs for in-specie transfer. We
            cannot accept in-specie transfers of Vanguard’s Index or Active funds (this includes LifeStrategy funds).
            <br />
            <br />
            Any assets in your current pension plan that cannot be transferred in-specie will be sold and transferred as
            cash. A partial in-specie transfer will mean that only those funds that are available under the InvestEngine
            Personal Pension will be transferred. Any other assets will remain in your current pension plan. There may
            be a charge with your current pension provider for transferring in-specie.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={48}>
        <Typography size={desktop ? 24 : 18} weight="semibold" lineHeight="small">
          <Typo>Declaration</Typo>
        </Typography>
      </Paper>
      <Paper top={32}>
        <Typography weight="semibold">To the transferring scheme</Typography>
      </Paper>
      <Paper top={24}>
        <Typography size={14}>
          <Typo>
            I authorise and instruct you to transfer funds from the transferring scheme as listed in the “Transferring
            Scheme Information” section of this form directly to my InvestEngine Personal Pension.
            <br />
            <br />I authorise you to release all necessary information to InvestEngine and Quai Investment Services
            Limited to enable the transfer of funds to my InvestEngine Personal Pension.
            <br />
            <br />I have read all the information provided or made available to me by the transferring scheme as listed
            in the “Transferring Scheme Details” section of this application.
            <br />
            <br />I accept that in order to comply with regulator obligations, Quai Investment Services Limited and the
            transferring scheme named in this application may need to verify my identity and residential address and may
            use credit reference agency services and ask for my documents to verify my identity and address.
            <br />
            <br />
            Until the application is accepted, and complete, Quai Investment Services Limited responsibility is limited
            to the return of the total payment(s) to the transferring scheme.
            <br />
            <br />I accept that I will be responsible for any losses and / or expenses which are the result, and which a
            reasonable person would consider to be the probable result, of any untrue, misleading, or inaccurate
            information deliberately or carelessly given by me, or on my behalf, either in this form or with respect to
            benefits from the transferring scheme(s).
            <br />
            <br />
            Where the payment(s) made to my InvestEngine Personal Pension represents all of the funds under the
            transferring scheme(s) listed in the “Transferring Scheme Information” section of this form, then payment
            made as instructed will mean that I shall no longer be entitled to receive pension or other benefits from
            the transferring scheme(s) listed.
            <br />
            <br />
            Where the payments made to my InvestEngine Personal Pension represent part of the fund under the plan(s)
            listed in the “Transferring Scheme Details” section of this application, then payment made as instructed
            will mean that I shall no longer be entitled to receive pension or other benefits from that part of the
            transferring scheme(s) represented by the payment.
            <br />
            <br />I promise to accept responsibility in respect of any claims, losses and expenses that my InvestEngine
            Personal Pension and the transferring scheme may incur as a result of any incorrect information provided by
            me in this application or any failure on my part to comply with any aspect of this application.
            <br />
            <br />I confirm that the value of any tax-free cash I have already taken combined with the benefits I am
            taking now are below my personal Lump Sum Allowance and Lump Sum and Death Benefit Allowance.
            <br />
            <br />I will be solely responsible for any additional tax charges or any penalties which arise if the
            information provided in this application is incorrect or if I have failed to comply with any aspect of this
            application.
            <br />
            <br />
            In addition, I promise to accept responsibility in respect of any claims, losses and expenses that Quai
            Investment Services Limited and the transferring scheme may incur as a result of any incorrect information
            provided by me in this application or of any failure on my part to comply with my aspect of this
            application.
          </Typo>
        </Typography>
      </Paper>
      <Paper top={48}>
        <LabelInlineStyle labelText="Date" size="small" multiline>
          <Paper top={4} data-test-id="signatureDate">
            {formatDate(moment(new Date()).tz('Europe/London').startOf('day').toDate(), 'DD/MM/YYYY')}
          </Paper>
        </LabelInlineStyle>
        <Paper top={16}>
          <Typography size={desktop ? 16 : 14}>
            <Typo>This pension transfer form will be submitted electronically.</Typo>
          </Typography>
        </Paper>
      </Paper>
      <Paper top={48}>
        <CheckboxWithLabel
          label={
            <Typography size={14}>
              <Typo>I understand and agree with the terms provided in both the disclaimer and declaration</Typo>
            </Typography>
          }
          size="smaller"
          checked={agreed}
          onChange={(agreed: boolean) => {
            setAgreed(agreed)
          }}
          required
        />
      </Paper>

      <Preloader loading={isLoading} background="background" delay={400} zIndex />
    </Form>
  )

  if (desktop) {
    return (
      <Modal open={isOpened} onClose={handleClose} close={null}>
        <Width size={38} style={{ minHeight: '600px' }}>
          <Paper top={8} bottom={8} right={24} left={24}>
            {headerNode}
          </Paper>
          <Paper top={80} bottom={80}>
            <Width size={30} center>
              {content}
              <Paper top={56}>
                <Width size={21.5} center>
                  {buttonNode}
                </Width>
              </Paper>
            </Width>
          </Paper>
        </Width>
      </Modal>
    )
  }
  return (
    <Modal open={isOpened} onClose={handleClose} close={null}>
      <MobileLayout
        header={headerNode}
        content={<AllCenter>{content}</AllCenter>}
        footer={<MobileLayoutFooterButton>{buttonNode}</MobileLayoutFooterButton>}
      />
    </Modal>
  )
})

export { SippTransferModal }
