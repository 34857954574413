import React, { forwardRef } from 'react'

import classNames from 'classnames/dedupe'

import { useCallback, useEffect, useState } from 'hooks'

import { formLinkToHost } from 'helpers/formLinkToHost.js'

import './SecurityLogo.css'

const SECURITY_LOGO_SIZES = [16, 24, 32, 36, 48] as const

type SecurityLogoProps = {
  className?: string
  logo?: string | null
  size?: (typeof SECURITY_LOGO_SIZES)[number]
  showStub?: boolean
  grayLogo?: boolean
  'data-test-id'?: string
}

const SecurityLogo = forwardRef<HTMLDivElement, SecurityLogoProps>(function SecurityLogo(
  {
    className,
    logo: logoProp,
    size = SECURITY_LOGO_SIZES[0],
    showStub = true,
    grayLogo = false,
    'data-test-id': dataTestId,
  }: SecurityLogoProps,
  ref,
) {
  const [logo, setLogo] = useState(logoProp)
  const [loading, setLoading] = useState(logo)

  const classes = classNames(className, 'SecurityLogo', {
    [`SecurityLogo_size_${size}`]: size,
    SecurityLogo_showStub: showStub,
    SecurityLogo_grayLogo: grayLogo,
    SecurityLogo_loading: loading,
  })

  const handleError = useCallback(() => {
    setLogo(null)
  }, [])

  const handleLoad = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    setLogo(logoProp)
  }, [logoProp])

  return logo ? (
    <div className={classes} data-test-id={dataTestId}>
      <img
        ref={ref}
        className="SecurityLogo-Image"
        src={formLinkToHost(logo)}
        width={size}
        height={size}
        onError={handleError}
        onLoad={handleLoad}
        alt=""
        data-test-id={dataTestId ? `${dataTestId}Img` : null}
      />
    </div>
  ) : (
    <span ref={ref} className={classNames(classes, 'SecurityLogo_empty')} data-test-id={dataTestId} />
  )
})

export { SecurityLogo, SECURITY_LOGO_SIZES }
