enum TransferCurrentYear {
  NO = 'NO',
  YES = 'YES',
}

enum TransferPreviousYear {
  NO = 'NO',
  ALL = 'ALL',
  PART = 'PART',
}

export { TransferCurrentYear, TransferPreviousYear }
