export const prepareName = (name) => {
  // first regExp replace duplicating \s or ' or - to single character
  // second regExp remove \s or ' or - from start and end
  // third regExp replace [a-z] charcters on first position or after \s or ' or - to uppercased
  return name
    .toLowerCase()
    .replace(/[\s'-]{2,}/g, (match) => match[0])
    .replace(/(^[\s'-])|([\s'-]$)/g, '')
    .replace(/(^\S{1})|([\s'-]\S{1})/g, (match) => match.toUpperCase())
}
