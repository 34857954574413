import React from 'react'

import { useUnit } from 'effector-react'

import { useMediaQueries, useActions } from 'hooks'

import { hardCodedCountries } from 'helpers/hardCodedCounties'

import { $owner } from 'app/effector/contacts'

import { showSupportModal as showSupportModalActionCreator } from 'app/redux/actions/ui'

import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'

import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import './OwnerDetails.css'

type OwnerDetailLineProps = {
  label: string
  detail?: string
  dataTestId?: string
}

function OwnerDetailLine({ label, detail, dataTestId }: OwnerDetailLineProps): React.ReactElement | null {
  return detail ? (
    <div className="OwnerDetails-Detail">
      <dt>
        <Typography size={14} color="additional" lineHeight="small" tag="span">
          {label}
        </Typography>
      </dt>
      <dd className="OwnerDetails-Description">
        <Typography lineHeight="small" tag="span" data-test-id={dataTestId}>
          <Typo>{detail}</Typo>
        </Typography>
      </dd>
    </div>
  ) : null
}

function OwnerDetails(): React.ReactElement | null {
  // quick fix for https://app.asana.com/0/1202304843784991/1202635259316452/f
  const countries = hardCodedCountries // useSelector((state) => state.dicts.countries)
  const [showSupportModal] = useActions([showSupportModalActionCreator])
  const { desktop } = useMediaQueries()

  const owner = useUnit($owner)

  return owner && countries ? (
    <Stack vertical={32}>
      <dl className="OwnerDetails-List">
        {owner.getFullName && (
          <OwnerDetailLine label="Name" detail={owner.getFullName()} dataTestId="ownerDetailsName" />
        )}
        <OwnerDetailLine label="Email" detail={owner.email} dataTestId="ownerDetailsEmail" />
        <OwnerDetailLine
          label="Address"
          detail={owner.addresses?.getCurrentAddress()[0]?.getAddressString(countries)}
          dataTestId="ownerDetailsAddress"
        />
      </dl>

      <Link onClick={showSupportModal} scrollToTop={false}>
        <Typo>
          <Typography size={desktop ? 18 : 16} lineHeight="small" inline>
            If any of the details above are incorrect,
          </Typography>{' '}
          <Typography size={desktop ? 18 : 16} color="inherit" inline>
            email us.
          </Typography>
        </Typo>
      </Link>
    </Stack>
  ) : null
}

export { OwnerDetails }
