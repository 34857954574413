import React from 'react'

import isFunction from 'lodash/isFunction'

import { useCallback, useMediaQueries } from 'hooks'

import { palette } from 'helpers/palette/'

import { Typo } from 'components/_old/Typo/Typo'

import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { Typography } from 'components/atoms/Typography'

import { CardNew } from '../CardNew'
import { SecurityLogo } from '../SecurityLogo'

import './LogoLabel.css'

type LogoLabelProps = {
  icon: string | null
  text: string | null
  href: string
  onClick: () => void
}

const LogoLabel = ({ icon, text, href, onClick }: LogoLabelProps): React.ReactElement => {
  const { desktop } = useMediaQueries()

  const handleClick = useCallback(
    (event) => {
      const isOpenInNewTab = event?.ctrlKey || event?.metaKey
      const hasHref = href && href.length > 0

      if (event && hasHref && !isOpenInNewTab) {
        event.preventDefault()
      }

      if (!isOpenInNewTab && isFunction(onClick)) {
        onClick()
      }
    },
    [href, onClick],
  )

  return (
    <a href={href} onClick={handleClick}>
      <CardNew borderRadius={10} shadow={desktop ? 4 : 3} backgroundColor={palette['background-card']}>
        <div className="LogoLabel">
          <ItemWithIcon
            space={16}
            icon={<SecurityLogo logo={icon} size={24} />}
            content={
              <Typography inline>
                <Typo>{text}</Typo>
              </Typography>
            }
            iconVerticalAlign="center"
          />
        </div>
      </CardNew>
    </a>
  )
}

export { LogoLabel }
