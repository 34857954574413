import React, { Fragment, useEffect } from 'react'

import { useMediaQueries, useState, useSelector, useActions } from 'hooks'

import { fetchPortfolios as fetchPortfoliosActionCreator } from 'app/redux/actions/portfolios'
import type { PortfolioList } from 'app/redux/models/portfolio'

import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo'
import Width from 'components/_old/Width/Width'

import { CardAccent } from 'components/atoms/CardAccent'
import { ItemWithIcon } from 'components/atoms/ItemWithIcon'
import { MobileLayout } from 'components/atoms/Layouts'
import { NavigationBar } from 'components/atoms/NavigationBar'
import { Paper } from 'components/atoms/Paper'
import { SelectableCard } from 'components/atoms/SelectableCard'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'
import { Validate } from 'components/atoms/Validate'

import { Modal } from 'components/molecules/Modal'

import { portfolioColors, regulatoryTypes, manageTypes } from 'constants/portfolio'

interface PortfolioSelectProps {
  value: number | 'new' | null
  validation: {
    rules: boolean[]
    errors: string[]
  }
  setValue: (value: any) => void
  isForceValidationEnabled: boolean
}

export const PortfolioSelect: React.FC<PortfolioSelectProps> = ({
  value: portfolioId,
  validation,
  setValue,
  isForceValidationEnabled,
}) => {
  const portfolios: PortfolioList = useSelector((state: { portfolios: { list: PortfolioList } }) =>
    (
      state.portfolios.list
        .getVisiblePortfolios()
        .filterByShape({ regulatory_type: regulatoryTypes.ISA, manage_type: manageTypes.DIY })
        .filter((portfolio) => portfolio.isActive()) as PortfolioList
    ).add({
      id: 'new',
      title: 'Create new DIY portfolio',
      manage_type: manageTypes.DIY,
      regulatory_type: regulatoryTypes.ISA,
    }),
  )

  const isPortfolioSelected = portfolioId !== null
  const currentPortfolio = portfolioId ? portfolios.get(portfolioId) : null

  const { desktop } = useMediaQueries()
  const [isModalOpen, setModalOpen] = useState(false)
  const [fetchPortfolios] = useActions([fetchPortfoliosActionCreator])

  const handleOpenModal = (): void => {
    setModalOpen(true)
  }

  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  const handleSelect = (portfolioId: number | null): void => {
    setValue(portfolioId)
    handleCloseModal()
  }

  useEffect(() => {
    if (portfolios.length === 1) {
      fetchPortfolios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const portfoliosNode = (
    <Stack vertical={16}>
      {portfolios.map((portfolio) => (
        <SelectableCard
          key={portfolio.id}
          onClick={() => {
            handleSelect(portfolio.id)
          }}
        >
          <Link mods={{ color: 'black' }}>
            {portfolio.id && portfolio.id !== 'new' && (
              <CardAccent color={portfolioColors[portfolio.manage_type]} data-test-id="portfolioCardAccent" />
            )}
            <Paper top={24} right={24} bottom={24} left={20}>
              <Typography
                size={18}
                weight="semibold"
                lineHeight="small"
                color="inherit"
                data-test-id="selectablePortfolioTitle"
              >
                <Typo>{portfolio.getTitle()}</Typo>
              </Typography>
              <Paper top={8}>
                <Typography size={14} lineHeight="small" color="additional">
                  <Typo>{portfolio.getDescription()}</Typo>
                </Typography>
              </Paper>
            </Paper>
          </Link>
        </SelectableCard>
      ))}
    </Stack>
  )

  const modalContentNode = desktop ? (
    <Paper top={80} bottom={80}>
      <Width size={42}>
        <Width size={30} center>
          <Typography size={32} weight="semibold" lineHeight="small">
            <Typo>Where would you like to receive the transferred assets?</Typo>
          </Typography>
          <Paper top={32}>{portfoliosNode}</Paper>
        </Width>
      </Width>
    </Paper>
  ) : (
    <MobileLayout
      header={<NavigationBar rightPartText="Close" onRightPartClick={handleCloseModal} />}
      content={
        <Fragment>
          <Typography size={24} weight="semibold" lineHeight="medium">
            <Typo>Where would you like to receive the transferred assets?</Typo>
          </Typography>
          <Paper top={24} bottom={24}>
            {portfoliosNode}
          </Paper>
        </Fragment>
      }
    />
  )

  return (
    <Validate rules={validation.rules} skipWaitBlur={isForceValidationEnabled}>
      {(valid, brokenRule, _handleFocus, handleBlur) => {
        return (
          <React.Fragment>
            {!valid && (
              <Paper bottom={32}>
                <Typography size={14} color="error">
                  <Typo>{validation.errors[brokenRule]}</Typo>
                </Typography>
              </Paper>
            )}
            <Width size={24}>
              <SelectableCard onClick={handleOpenModal} data-test-id="selectedPortfolio">
                <Link mods={{ color: 'black' }}>
                  <Paper top={24} right={24} bottom={24} left={20}>
                    {isPortfolioSelected && currentPortfolio ? (
                      <ItemWithIcon
                        icon={<Icon type="arrow-down-16" size={16} fixedSize color="inherit" />}
                        iconPosition="right"
                        iconVerticalAlign="center"
                        content={
                          <Fragment>
                            <Typography
                              size={18}
                              weight="semibold"
                              lineHeight="small"
                              color="inherit"
                              data-test-id="selectedPortfolioTitle"
                            >
                              <Typo>{currentPortfolio.getTitle()}</Typo>
                            </Typography>
                            <Paper top={8}>
                              <Typography
                                size={14}
                                lineHeight="small"
                                color="additional"
                                data-test-id="selectedPortfolioTypes"
                              >
                                <Typo>{currentPortfolio.getDescription()}</Typo>
                              </Typography>
                            </Paper>
                          </Fragment>
                        }
                        contentVerticalAlign="center"
                      />
                    ) : (
                      <ItemWithIcon
                        icon={<Icon type="arrow-down-16" size={16} fixedSize color="inherit" />}
                        iconPosition="right"
                        iconVerticalAlign="center"
                        content={
                          <Fragment>
                            <Typography size={18} weight="semibold" lineHeight="small" color="inherit">
                              <Typo>Choose portfolio</Typo>
                            </Typography>
                            <Paper top={8}>
                              <Typography size={14} lineHeight="small" color="additional">
                                <Typo>Destination for your ISA transfer</Typo>
                              </Typography>
                            </Paper>
                          </Fragment>
                        }
                        contentVerticalAlign="center"
                      />
                    )}
                  </Paper>
                </Link>
              </SelectableCard>
            </Width>
            <Modal
              open={isModalOpen}
              onClose={() => {
                handleCloseModal()
                handleBlur()
              }}
              close={desktop ? <Typography color="inherit">Close</Typography> : null}
              data-test-id="portfolioSelectorModal"
            >
              {modalContentNode}
            </Modal>
          </React.Fragment>
        )
      }}
    </Validate>
  )
}
