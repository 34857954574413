import React, { Fragment } from 'react'

import classNames from 'classnames/dedupe'

import { useMediaQueries } from 'hooks'

import { querystringFromObject } from 'helpers/ajax/querystring'
import { trackEvent } from 'helpers/analytics'
import { isDarkTheme } from 'helpers/palette'
import { urlTo } from 'helpers/router'

import { type Collection } from 'app/effector/discover-etfs/types'

import { TooltipToModal } from 'components/_old/atoms/TooltipToModal/TooltipToModal.jsx'
import Icon from 'components/_old/Icon/Icon.jsx'
import Link from 'components/_old/Link/Link.jsx'
import { Typo } from 'components/_old/Typo/Typo'

import { Banner, type Theme as BannerTheme } from 'components/atoms/Banner'
import { Paper } from 'components/atoms/Paper'
import { Skeleton } from 'components/atoms/Skeleton'
import { Typography } from 'components/atoms/Typography'

import { BannerLogo } from 'components/organisms/BannerLogo'

import { PopularEtfs } from './PopularEtfs'

import './CollectionGroup.css'

const gaCollectionClickEvents = {
  'Featured partners': {
    eventName: 'etfrange_featured_partner_clicked',
    collectionIdParameter: 'featured_partner_id',
  },
  Collections: {
    eventName: 'etfrange_collection_clicked',
    collectionIdParameter: 'collection_id',
  },
  'Industries & Sectors': {
    eventName: 'etfrange_sector_clicked',
    collectionIdParameter: 'sector_id',
  },
}

type CollectionGroupProps = {
  addBackLocationBeforeNavigation?: boolean
  areCollectionsBeingLoaded: boolean
  collections: Collection[]
  isDiscoveryDisplayed: boolean
  isLoading: boolean
  isSkeleton: boolean
  layout: 'block' | 'featured' | 'inline'
  portfolioId?: string
  requestRegulatoryType?: string
  scrollableElementRef: React.RefObject<any>
  securities: any[]
  securitiesWasLoadedOnce: boolean
  title: string
  portfolioSecurities: any[]
  getSecurityDataByIndex: (index: number) => any
  getSecurityLink: (security: any) => string
  handleSaveScrollPosition: () => void
  handleSecurityClick: (security: any) => void
  navigateByTab: (index: number) => void
}

const CollectionGroup = ({
  addBackLocationBeforeNavigation = false,
  areCollectionsBeingLoaded,
  collections = [],
  isDiscoveryDisplayed,
  isLoading,
  isSkeleton,
  layout = 'block',
  portfolioId,
  portfolioSecurities,
  requestRegulatoryType,
  scrollableElementRef,
  securities,
  securitiesWasLoadedOnce,
  title,
  getSecurityDataByIndex,
  getSecurityLink,
  handleSaveScrollPosition,
  handleSecurityClick,
  navigateByTab,
}: CollectionGroupProps): React.ReactElement | null => {
  const { desktop } = useMediaQueries()

  if (layout === 'inline') {
    const allVisibleSecuritiesAmount = securities.filter((sec) => sec.is_visible_in_universe).length
    const popularEtfsIds = collections[0].security_ids

    let popularEtfs = (popularEtfsIds ?? [])
      .map((popularEtfsId) => securities.find((security) => security.id === popularEtfsId))
      .filter((securityOrNull) => !!securityOrNull)

    if (isLoading || isSkeleton) {
      popularEtfs = new Array(3).fill({ skeleton: true })
    }

    return (
      <PopularEtfs
        allVisibleSecuritiesAmount={allVisibleSecuritiesAmount}
        areCollectionsBeingLoaded={areCollectionsBeingLoaded}
        isDiscoveryDisplayed={isDiscoveryDisplayed}
        isLoading={isLoading}
        portfolioSecurities={portfolioSecurities}
        scrollableElementRef={scrollableElementRef}
        securities={popularEtfs}
        securitiesWasLoadedOnce={securitiesWasLoadedOnce}
        getSecurityDataByIndex={getSecurityDataByIndex}
        getSecurityLink={getSecurityLink}
        handleSaveScrollPosition={handleSaveScrollPosition}
        handleSecurityClick={handleSecurityClick}
        navigateByTab={navigateByTab}
      />
    )
  }

  const isFeatured = layout === 'featured'

  const listContainerClassNames = classNames('CollectionGroup_List', {
    CollectionGroup_List_featured: isFeatured,
  })

  const sendGaEvent = (collection): void => {
    trackEvent({
      action: gaCollectionClickEvents[title].eventName,
      [gaCollectionClickEvents[title].collectionIdParameter]: collection.id,
    })
  }

  const collectionName = window.location.pathname.match(/\/collections\/([^/]+)/)?.[1]

  return (
    <div className="CollectionGroup" data-test-id="collectionGroup">
      <div className="CollectionGroup-Wrapper">
        <Skeleton shown={isSkeleton} inline>
          <Fragment>
            <Typography
              size={desktop ? 24 : 20}
              weight="semibold"
              lineHeight="small"
              data-test-id="discoverySectionTitle"
              inline
            >
              <Typo>{title}</Typo>
            </Typography>
            {/* {isFeatured && (
              <Paper left={8} inline>
                <Link
                  to="https://blog.investengine.com/featured-partners/"
                  mods={{ color: 'black' }}
                  blank
                  style={{ top: '5px', position: 'relative' }}
                  data-test-id="featuredPartnersTooltip"
                >
                  <Icon type="information-24" inline />
                </Link>
              </Paper>
            )} */}
            {isFeatured && (
              <Paper left={8} inline className="CollectionGroup-Popup">
                <TooltipToModal
                  description={
                    <Typography tag="p" size={14} weight={600} lineHeight="medium" textWrap="pretty">
                      <Typo>
                        We work with leaders in the industry to co-create educational content and events as part of paid
                        partnerships. This ensures we can bring high-quality, relevant material to you to help inform
                        your investment decisions, however it should not be taken as investment advice or a personal
                        recommendation.
                        <br />
                        <Link to="https://blog.investengine.com/featured-partners/" blank>
                          Read more
                        </Link>
                      </Typo>
                    </Typography>
                  }
                  zIndex={1001}
                  customIcon
                >
                  {/* div is necessary for the popup to work on mobile */}
                  <div>
                    <Icon type="information-24" data-test-id="featuredPartnersTooltip" />
                  </div>
                </TooltipToModal>
              </Paper>
            )}
          </Fragment>
        </Skeleton>
      </div>
      <Paper top={desktop ? 24 : 16} className={listContainerClassNames}>
        {collections.map((collection) => (
          <Skeleton shown={isSkeleton} key={collection.id} className="CollectionGroup_Item">
            <Link
              to={urlTo(
                'security-collection',
                { slugOrId: collection.slug || collection.id },
                querystringFromObject({
                  portfolioId,
                  requestRegulatoryType,
                  back: addBackLocationBeforeNavigation ? window.location.pathname + window.location.search : null,
                  fromCollection: collectionName ?? null,
                }),
              )}
              onClick={() => {
                sendGaEvent(collection)
                if (handleSaveScrollPosition) handleSaveScrollPosition()
              }}
            >
              {isFeatured ? (
                <BannerLogo title={collection.title} backgroundImage={collection.background_small} />
              ) : (
                <Banner
                  title={collection.title}
                  shortDescription={collection.short_description}
                  topRightContent={
                    collection.security_ids &&
                    `${collection.security_ids?.length} ETF${collection.security_ids?.length > 1 ? 's' : ''}`
                  }
                  theme={collection.style as BannerTheme}
                  backgroundImage={collection.background_small}
                  backgroundColor={collection.background_color}
                  icon={
                    <img
                      style={{ maxHeight: isFeatured ? '21px' : '24px', maxWidth: isFeatured ? '110px' : '24px' }}
                      src={isDarkTheme() && collection.icon_dark_theme ? collection.icon_dark_theme : collection.icon}
                    />
                  }
                />
              )}
            </Link>
          </Skeleton>
        ))}
      </Paper>
    </div>
  )
}

export { CollectionGroup }
